import React, { useEffect } from 'react';
import {useDispatch} from 'react-redux';
import {GET_USER_REGISTRATION_URL} from '../../../api';
import {getUserStatistic, deleteUser} from '../../../redux/actions';
import 'datatables.net';
import 'datatables.net-responsive';
import moment from 'moment';
import $ from "jquery";
import cogoToast from "cogo-toast";
import {Link} from "react-router-dom";
import {capitalize} from "../../../utils/capitalize";

const Registration = (props) => {

    document.title = "User Registration";

    const token = localStorage.getItem('jwtToken');
    const name = props.match.params.name;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUserStatistic());

        $(function () {
            var table = $('#user').DataTable({
                responsive: true,
                "language": {
                    "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting User Report</p>'
                },
                "searching": true,
                "lengthMenu": [[10], [10]],
                ordering: false,
                info: true,
                bFilter: false,
                processing: true,
                pageLength: 10,
                serverSide: true,
                ajax: function(data, callback) {
                    $.ajax(GET_USER_REGISTRATION_URL, {
                        type: 'POST',
                        headers: {
                            "Authorization": token
                        },
                        data: {
                            query: $('.dataTables_filter input').val(),
                            pageSize: data.start,
                            country: name.replace(/'/g, ""),
                            format: 'json',
                            pageIndex: (data.length + data.start) / data.length
                        },
                        success : function(res) {
                            callback({
                                recordsTotal: res.total,
                                recordsFiltered: res.total,
                                data: res.data
                            });
                        }
                    });
                },
                "columns": [
                    {"data": "raw"},
                    {"data": "raw"},
                    {"data": "raw"},
                    {"data": "raw"},
                    {"data": "raw"},
                    {"data": "raw"}
                ],
                "columnDefs": [
                    {
                        "render": function (data) {
                            return `+${data.phone}`
                        },
                        "targets": 0
                    },
                    {
                        "render": function (data) {
                            if(data.location.length !== 0) {
                                return data.location.data.slice(-1).pop().region
                            } else {
                                return "No record"
                            }
                        },
                        "targets": 1
                    },
                    {
                        "render": function (data) {
                            try {
                                return capitalize(data.device.brand)
                            } catch (e) {
                                return "No record"
                            }
                        },
                        "targets": 2
                    },
                    {
                        "render": function (data) {
                            try {
                                return data.device.model
                            } catch (e) {
                                return "No record"
                            }
                        },
                        "targets": 3
                    },
                    {
                        "render": function (data) {
                            return moment(data.dateCreated).format("Do MMM, YYYY HH:mm");
                        },
                        "targets": 4
                    },
                    {
                        "render": function () {
                            return `<nav class="nav nav-icon-only"><a href="#" class="nav-link outline-none show_modal"><i class="fa fa-eye"></i> View</a><a href="#" class="nav-link delete_user"><i class="fa fa-trash-alt"></i> Delete</a></nav>`
                        },
                        "targets": 5
                    }
                ]
            });

            $('#user').on('click', '.show_modal', function(){
                let extract_td = table.row( $(this).closest('tr') ).data();
                let _result = {
                    user: extract_td.raw
                };

                dispatch({
                    type: 'USER_SEARCH',
                    payload: _result
                });

                $('#user_modal').modal({
                    backdrop: 'static',
                    keyboard: false
                });

                $("#user_modal").modal("show");
                return false;
            });

            $('#user').on('click', '.delete_user', function() {
                let extract_td = table.row($(this).closest('tr')).data();
                const options = {
                    position: "top-right",
                    hideAfter: 0
                };

                let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

                deleteUser(extract_td.raw._id).then((result) => {
                    hide();
                    const _options = {
                        position: "top-right"
                    };

                    if(result.error === false) {
                        dispatch(getUserStatistic());
                        $('#user').DataTable().ajax.reload(null, false);
                        cogoToast.success(result.data, _options);
                    } else {
                        cogoToast.error(result.data, _options);
                    }
                });

                return false;
            });

        });
    }, []);

    const handleReloadTable = () => {
        $('#user').DataTable().ajax.reload(null, false);
    };

    return (
        <div className="content-body ht-100v pd-b-0">
            <div className="container pd-x-0">
                <div
                    className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                                <li className="breadcrumb-item active" aria-current="page">User Registration</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">User Registration</h4>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Latest Trend</h6>
                                <div className="d-flex tx-18">
                                    <Link to="#" className="link-03 lh-0" onClick={handleReloadTable.bind()}><i className="icon ion-md-refresh"></i></Link>
                                    {/*<a href="" className="link-03 lh-0 mg-l-10"><i className="icon ion-md-more"></i></a>*/}
                                </div>
                            </div>
                            <div className="card-body">
                                <table id="user" className="table">
                                    <thead>
                                    <tr>
                                        <th className="wd-15p">Phone</th>
                                        <th className="wd-20p">Region</th>
                                        <th className="wd-15p">Brand</th>
                                        <th className="wd-20p">Model</th>
                                        <th className="wd-15p">Date</th>
                                        <th className="wd-15p">Actions</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Registration;
