import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import 'components-jqueryui';
import 'parsleyjs';
import {useSelector} from 'react-redux';
import cogoToast from "cogo-toast";
import 'select2';
import axios from "axios";
import {UPLOAD_CAMPAIGN_URL, GET_REGION_DATA, GET_ADVERTISER_DATA} from "../../../api";
import interests from "../../../persist/interests";
import {Link, NavLink} from "react-router-dom";
import formatNumber from "../../../utils/formatNumber";

const CreateAd = (props) => {

    document.title = "Create Campaign";

    const option = {
        hideAfter: 5
    };

    const token = localStorage.getItem('jwtToken');
    const auth = useSelector((state) => state.auth);

    const region_info = 'Please note that selecting a specific suburb or city means that your ad shall only be placed in this specific targeted area. Choosing the province without specifying the suburb or city means that your ad is shown in every suburb or city within that province that has active AdzLok users on the platform. This option is available for you to target specific areas of interest to you and your business.';

    const [ads_raw, set_ads_raw] = useState([]);
    const [ads, set_ads] = useState([]);
    const [upload_image, set_upload_image] = useState([]);
    const [img_src, set_img_src] = useState("");
    const [img_name, set_img_name] = useState("");
    const [img_preview, set_img_preview] = useState("");
    const [_budget_balance, set_budget_balance] = useState(0);
    const [start_hour, set_start_hour] = useState(0);
    const [end_hour, set_end_hour] = useState(23);
    const [country, setCountry] = useState("");
    const [switch_button, set_switch_button] = useState(false);
    const [stateReady, setStateReady] = useState(false);
    const [previous, _previous] = useState("");
    const [reserve_balance, set_reserve_balance] = useState(0);
    const [_index, __index] = useState(0);
    const [ad_img, set_ad_img] = useState("");
    const [cpe_price, set_cpe_price] = useState(0.20);
    const [cpc_price, set_cpc_price] = useState(0.30);
    const [cpa_price, set_cpa_price] = useState(0.50);
    const [email, setEmail] = useState("");
    const [duration, set_duration] = useState([
        {key: "1", name: "1"},
        {key: "7", name: "7"},
        {key: "28", name: "28"},
        {key: "84", name: "84"},
        {key: "168", name: "168"},
        {key: "252", name: "252"},
        {key: "336", name: "336"}
    ]);

    $(function () {
        $('[data-toggle="popover"]').popover({
            animation: true
        });

        $('body').on('click', function (e) {
            //did not click a popover toggle or popover
            if ($(e.target).data('toggle') !== 'popover'
                && $(e.target).parents('.popover.in').length === 0) {
                $('[data-toggle="popover"]').popover('hide');
            }
        });

        var app = app || {};

        // Utils
        (function ($, app) {
            'use strict';

            app.utils = {};

            app.utils.formDataSuppoerted = (function () {
                return !!('FormData' in window);
            }());

        }($, app));

        // Parsley validators
        (function ($, app) {
            'use strict';

            window.Parsley
                .addValidator('filemaxmegabytes', {
                    requirementType: 'string',
                    validateString: function (value, requirement, parsleyInstance) {
                        if (!app.utils.formDataSuppoerted) {
                            return true;
                        }

                        var file = parsleyInstance.$element[0].files;
                        var maxBytes = requirement * 1048576;

                        if (file.length === 0) {
                            return true;
                        }

                        return file.length === 1 && file[0].size <= maxBytes;

                    },
                    messages: {
                        en: 'File is to big'
                    }
                }).addValidator('filemimetypes', {
                requirementType: 'string',
                validateString: function (value, requirement, parsleyInstance) {

                    if (!app.utils.formDataSuppoerted) {
                        return true;
                    }

                    var file = parsleyInstance.$element[0].files;

                    if (file.length === 0) {
                        return true;
                    }

                    var allowedMimeTypes = requirement.replace(/\s/g, "").split(',');
                    return allowedMimeTypes.indexOf(file[0].type) !== -1;

                },
                messages: {
                    en: 'Only jpeg, gif and png is allowed'
                }
            }).addValidator('filemaxmegabytes', {
                requirementType: 'string',
                validateString: function (value, requirement, parsleyInstance) {

                    let file = parsleyInstance.$element[0].files[0];
                    // let [width, height] = requirement.split('x');
                    let image = new Image();
                    let deferred = $.Deferred();

                    image.src = window.URL.createObjectURL(file);
                    image.onload = function() {
                        if (image.height / image.width >= 1.5) {
                            deferred.resolve();
                        }
                        else {
                            deferred.reject();
                        }
                    };

                    return deferred.promise();
                },
                messages: {
                    en: 'Please upload portrait images only.'
                }
            });
        }($, app));
    });

    useEffect(() => {
        $(function () {
            $('#date_from').datepicker({
                showOtherMonths: true,
                selectOtherMonths: true,
                minDate: 2,
                defaultDate: '+1w',
                numberOfMonths: 1,
                dateFormat: 'yy/mm/dd'
            });

            $('#start_hour').prop('disabled', true);
            $('#end_hour').prop('disabled', true);
            $('#category_data').val("").prop('disabled', true);
            $("#target_url").attr("placeholder", "Not Applicable");
            $('#region_data').prop('disabled', true);
            $('#custom_cost').val("cpe");
            $('#ad_duration').val("");
            $('#gender').val("");
            $('#region_type').val("");
            $('#ad_category').val("");

            $('#_basic').parsley();

            $("#_basic").on('submit', function(e){
                e.preventDefault();
            });

            $('.ad-select2').select2({
                minimumResultsForSearch: Infinity,
                placeholder: 'Select'
            });

            $('.advertiser-select2').select2({
                minimumInputLength: 3,
                maximumSelectionLength: 1,
                placeholder: 'Enter advertiser email',
                ajax: {
                    url: GET_ADVERTISER_DATA,
                    dataType: 'json',
                    type: "POST",
                    headers: {
                        "Authorization" : token,
                    },
                    data: function (query) {
                        return {
                            query: query
                        };
                    },
                    processResults: function (data) {
                        return {
                            results: $.map(data, function (item) {
                                setCountry(item.country);
                                setEmail(item.email);
                                return {
                                    text: item.email,
                                    id: item
                                }
                            })
                        };
                    }
                }
            });

            $('.category-select2').select2({
                minimumResultsForSearch: Infinity,
                placeholder: 'Select'
            });
        });

        return () => {
            $("#date_from").datepicker("destroy");
            $('#_basic').parsley().destroy();
        }
    }, []);

    const handleCategorySelected = (event) => {
        if(event.target.value !== "") {
            $('#category_data').prop('disabled', false);
            $(".category-select2").empty().select2();

            const interest = interests.find((result) => result.key === event.target.value);

            if(interest.child.length === 0) {
                $('#category_data').prop('disabled', true);
            }

            $('.category-select2').select2({
                data: interest.child,
                placeholder: 'Select',
                dropdownParent: $('#modalCreateAd')
            });
        } else {
            $('#category_data').prop('disabled', true);
        }
    };

    const generateId = (length) => {
        let text = "";
        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < length; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    };

    const handleFileUpload = (event) => {
        if(event.target.files[0] !== undefined && $('#image').parsley().isValid() !== false) {
            let file = event.target.files[0], reader = new FileReader();
            reader.onload = function(r){
                set_img_preview(r.target.result);
            };
            reader.readAsDataURL(file);

            set_img_src(generateId(50));
            set_img_name(event.target.files[0].name);
            set_ad_img(event.target.files[0]);
        }
    };

    const handleNext = () => {
        if($('#campaign').parsley().isValid() && $('#budget').parsley().isValid() && $('#advertiser').parsley().isValid()) {
            balance(0, "topup");
            $('#campaign_basic .active').parent().next('li').find('a').trigger('click');
        }
    };

    const handlePrevious = () => {
        $('#campaign_basic .active').parent().prev('li').find('a').trigger('click');
    };

    const handleAdBasicNext = async () => {
        try {
            //Validate images
            let image_valid = await $('#image').parsley().whenValid({});

            //Validate if user in the edit ad.
            if($('#ad_name').parsley().isValid() && $('#ad_budget').parsley().isValid() && reserve_balance !== 0) {
                return $('#ad_pill .active').parent().next('li').find('a').trigger('click');
            }

            //Validate if user in the create ad.
            if($('#ad_name').parsley().isValid() && $('#ad_budget').parsley().isValid() && image_valid[0][0]) {
                return $('#ad_pill .active').parent().next('li').find('a').trigger('click');
            }

            return null;
        } catch (e) {
            return null;
        }
    };

    const handleAdDateNext = () => {
        if($('#date_from').parsley().isValid() && $('#ad_duration').parsley().isValid()) {
            $('#ad_pill .active').parent().next('li').find('a').trigger('click');
        }
    };

    const handleAdTimeNext = () => {
        if($('#custom_time').parsley().isValid() && $('#start_hour').parsley().isValid() && $('#end_hour').parsley().isValid() && $('#ad_category').parsley().isValid()) {
            calculateClickRate($('#custom_cost').val(), $('#ad_duration').val(), $('#ad_budget').val(), $('#custom_time').val());
            $('#ad_pill .active').parent().next('li').find('a').trigger('click');
        }
    };

    const handleAdCostNext = () => {
        if($('#target_url').parsley().isValid()) {
            $('#ad_pill .active').parent().next('li').find('a').trigger('click');
        }
    };

    const handleAdGenderNext = () => {
        if($('#gender').parsley().isValid() && $('#b1a').parsley().isValid() || $('#b2a').parsley().isValid() || $('#b3a').parsley().isValid() || $('#b4a').parsley().isValid()) {
            $('#ad_pill .active').parent().next('li').find('a').trigger('click');
        }
    };

    const handleAdPrevious = (event) => {
        event.preventDefault();
        $('#ad_pill .active').parent().prev('li').find('a').trigger('click');
    };

    const handleCustomTimeSelected = (event) => {
        if(event.target.value !== "normal") {
            set_cpe_price((0.20 * 1.5).toFixed(2));
            set_cpc_price((0.30 * 1.5).toFixed(2));
            set_cpa_price((0.50 * 1.5).toFixed(2));
            $('#start_hour').prop('disabled', false);
            $('#end_hour').prop('disabled', false);
        } else {
            set_cpe_price((0.20).toFixed(2));
            set_cpc_price((0.30).toFixed(2));
            set_cpa_price((0.50).toFixed(2));
            $('#start_hour').prop('disabled', true);
            $('#end_hour').prop('disabled', true);
        }
    };

    const handleCustomCostSelected = (event) => {
        calculateClickRate(event.target.value, $('#ad_duration').val(), $('#ad_budget').val(), $('#custom_time').val());
    };

    const calculateClickRate = (type, duration, value, custom) => {
        switch (type) {
            case "cpe":
                $('#target_url').prop('disabled', true);
                $('#target_url').prop('required', false);
                $("#target_url").attr("placeholder", "Not Applicable");
                let cpe = custom === "normal" ? 0.20 : 0.20 * 1.5;
                set_cpe_price(cpe.toFixed(2));
                $('#total_click').val((parseInt(value) / cpe.toFixed(2)).toFixed(0));
                $('#click_per_day').val(((parseInt(value) / cpe.toFixed(2)) / parseInt(duration)).toFixed(0));
                break;

            case "cpc":
                $('#target_url').prop('disabled', false);
                $('#target_url').prop('required', true);
                $("#target_url").attr("placeholder", "Enter website link");
                let cpc = custom === "normal" ? 0.30 : 0.30 * 1.5;
                set_cpc_price(cpc.toFixed(2));
                $('#total_click').val((parseInt(value) / cpc.toFixed(2)).toFixed(0));
                $('#click_per_day').val(((parseInt(value) / cpc.toFixed(2)) / parseInt(duration)).toFixed(0));
                break;

            case "cpa":
                $('#target_url').prop('disabled', false);
                $('#target_url').prop('required', true);
                $("#target_url").attr("placeholder", "Enter website link");
                let cpa = custom === "normal" ? 0.50 : 0.50 * 1.5;
                set_cpa_price(cpa.toFixed(2));
                $('#total_click').val((parseInt(value) / cpa.toFixed(2)).toFixed(0));
                $('#click_per_day').val(((parseInt(value) / cpa.toFixed(2)) / parseInt(duration)).toFixed(0));
                break;
        }
    };

    const handleRegionType = (event) => {
        let type = event.target.value;

        if(event.target.value !== "" && event.target.value !== "national") {
            $("#region_data").prop('required', true);
            $('#region_data').prop('disabled', false);

            $(".ad-select2").empty().select2();

            $('.ad-select2').select2({
                minimumInputLength: 3,
                placeholder: `Type`,
                allowClear: true,
                ajax: {
                    url: GET_REGION_DATA,
                    dataType: 'json',
                    type: "POST",
                    headers: {
                        "Authorization" : token,
                    },
                    data: function (query) {
                        return {
                            type: type,
                            query: query,
                            country: country
                        };
                    },
                    processResults: function (data) {
                        return {
                            results: $.map(data, function (item) {
                                return {
                                    text: `${item.payload} (${item.count})`,
                                    id: item.payload
                                }
                            })
                        };
                    }
                },
                dropdownParent: $('#modalCreateAd')
            });
        } else {
            $(".ad-select2").empty().select2();
            $("#region_data").prop('required', false);
            $('#region_data').prop('disabled', true);
        }
    };

    const balance = (value, type) => {
        if(type === "add") {
            set_budget_balance($('#budget').val() + parseInt(value));
        } else if(type === "topup") {
            const total = ads.reduce((acc, item) => acc+= parseInt(item.budget), 0);
            set_budget_balance($('#budget').val() - total);
        } else {
            set_budget_balance(set_budget_balance - value);
        }
    };

    const proposed_balance = () => {
        return $('#budget').val();
    }

    const rateBudget = (amount) => {
        if(auth.user.type === "agent") {
            return amount - amount * 0.15;
        }
        return amount;
    };

    const handleModal = () => {
        $('#ad_title').text("Create Ad");
        const total = ads.reduce((acc, item) => acc+= parseInt(item.budget), 0);
        if(total === parseInt($('#budget').val())) {
            return cogoToast.error("Please increase your clicks budget or reduce the number of clicks on chosen campaign.", option);
        }

        if(ads.length >= 5) {
            return cogoToast.error("You can only upload maximum of 5 Ads.");
        }

        $('#image').prop("required", true);
        set_switch_button(false);

        $('#modalCreateAd').modal({
            backdrop: 'static',
            keyboard: false
        });

        $('#basic_form').parsley();
        $('#date_form').parsley();
        $('#time_form').parsley();
        $('#cost_form').parsley();
        $('#gender_form').parsley();
        $('#target_form').parsley();

        $("#basic_form").on('submit', function(e){
            e.preventDefault();
        });

        $("#date_form").on('submit', function(e){
            e.preventDefault();
        });

        $("#time_form").on('submit', function(e){
            e.preventDefault();
        });

        $("#cost_form").on('submit', function(e){
            e.preventDefault();
        });

        $("#gender_form").on('submit', function(e){
            e.preventDefault();
        });

        $("#target_form").on('submit', function(e){
            e.preventDefault();
        });

        $("#modalCreateAd").modal("show");
    };

    const handleCreateAd = () => {
        if(
            $('#ad_name').parsley().isValid() == false ||
            $('#ad_budget').parsley().isValid() == false ||
            $('#ad_category').parsley().isValid() == false ||
            $('#image').parsley().isValid() == false ||
            $('#ad_duration').parsley().isValid() == false ||
            $('#custom_cost').parsley().isValid() == false ||
            $('#custom_time').parsley().isValid() == false ||
            $('#date_from').parsley().isValid() == false ||
            $('#gender').parsley().isValid() == false ||
            $('#region_type').parsley().isValid() == false ||
            $('#region_type').parsley().isValid() == true && $('#region_data').parsley().isValid() == false
        ) {
            return null;
        }

        $("#modalCreateAd").modal("hide");
        $(".modal-backdrop").remove();

        let data_raw = {
            index: img_src,
            name : $('#ad_name').val(),
            img_src: img_src,
            budget : $('#ad_budget').val(),
            duration : $('#ad_duration').val(),
            category : $('#ad_category').val(),
            start_date : $('#date_from').val(),
            custom_cost : {
                type : $('#custom_time').val(),
                start_hour : $('#start_hour').val(),
                end_hour : $('#end_hour').val()
            },
            cost_model : $('#custom_cost').val(),
            total_clicks : $('#total_click').val(),
            click_per_day : $('#click_per_day').val(),
            uri : $('#target_url').val(),
            gender : $('#gender').val(),
            extra_category: $('#category_data').val(),
            age : {
                group_a : $('#b1a').is(':checked'),
                group_b : $('#b2a').is(':checked'),
                group_c : $('#b3a').is(':checked'),
                group_d : $('#b4a').is(':checked')
            },
            region : {
                type : $('#region_type').val(),
                data : $('#region_data').val()
            }
        };

        let data = {
            index: img_src,
            img_preview: img_preview,
            ad_img: ad_img,
            name : $('#ad_name').val(),
            img_name: img_name,
            img_src: img_src,
            budget : $('#ad_budget').val(),
            duration : $('#ad_duration').val(),
            category : $('#ad_category').val(),
            start_date : $('#date_from').val(),
            custom_cost : {
                type : $('#custom_time').val(),
                start_hour : $('#start_hour').val(),
                end_hour : $('#end_hour').val()
            },
            cost_model : $('#custom_cost').val(),
            total_clicks : $('#total_click').val(),
            click_per_day : $('#click_per_day').val(),
            uri : $('#target_url').val(),
            gender : $('#gender').val(),
            extra_category: $('#category_data').val(),
            age : {
                group_a : $('#b1a').is(':checked'),
                group_b : $('#b2a').is(':checked'),
                group_c : $('#b3a').is(':checked'),
                group_d : $('#b4a').is(':checked')
            },
            region : {
                type : $('#region_type').val(),
                data : $('#region_data').val()
            }
        };

        set_ads_raw([...ads_raw, data_raw]);
        set_ads([...ads, data]);

        upload_image.push({
            index: img_src,
            raw: ad_img
        });

        set_upload_image(upload_image);
        clearAdForm();
    };

    const clearAdForm = () => {
        //Clear Form
        $("#ad_name").val(null);
        $('#ad_budget').val(null);
        $('#ad_duration').val(null);
        $('#region_type').val(null);
        $('#custom_cost').val("cpe");
        $(".ad-select2").empty().select2();
        $("#category_data").empty().select2();
        $('#ad_category').val("");
        $('#category_data').empty().select2();
        $('#image').val(null);
        $('#date_from').val(null);
        $('#target_url').val(null);
        $('#gender').val("");

        //Disable Form
        $('#start_hour').prop('disabled', true);
        $('#end_hour').prop('disabled', true);
        $("#target_url").attr("placeholder", "Not Applicable");
        $('#region_data').prop('disabled', true);
        $('#category_data').prop('disabled', true);
        $('#image').prop("disabled", false);

        // Parsley Destroy
        $('#basic_form').parsley().destroy();
        $('#date_form').parsley().destroy();
        $('#time_form').parsley().destroy();
        $('#cost_form').parsley().destroy();
        $('#gender_form').parsley().destroy();
        $('#target_form').parsley().destroy();

        $('#b1a').prop({checked: false});
        $('#b2a').prop({checked: false});
        $('#b3a').prop({checked: false});
        $('#b4a').prop({checked: false});

        set_reserve_balance(0);

        $('#ad_pill a:first').tab('show');
    };

    const handleUploadAd = () => {
        const formData = new FormData();

        $.each(upload_image, function(i, file) {
            formData.append('file', file.raw);
        });

        formData.append('name', $('#campaign').val());
        formData.append('budget', $('#budget').val());
        formData.append('advertiser', email);
        formData.append('ads', JSON.stringify(ads_raw));

        console.log($('#advertiser').val());

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };

        _previous("disabled");
        setStateReady(true);

        axios.post(UPLOAD_CAMPAIGN_URL, formData, config).then((response) => {
            let {payload} = response.data;
            if(payload === "success") {
                props.history.push('/campaign');
            }
        }).catch((err) => {
            _previous("");
            setStateReady(false);

            const option = {
                hideAfter: 3
            };

            cogoToast.error(err.response.data.error, option);
        });
    };

    const handleDeleteAd = (budget, index) => {
        let filteredArray = ads.filter(item => item.index !== index);
        let raw_filteredArray = ads_raw.filter(item => item.index !== index);
        let imageArray = upload_image.filter(item => item.index !== index);
        set_ads(filteredArray);
        set_ads_raw(raw_filteredArray);
        set_upload_image(imageArray);
    };

    const handleEditAdModal = (result, index) => {

        $('#ad_title').text("Edit Ad");
        set_reserve_balance(result.budget);

        // Parsley Initial
        $('#basic_form').parsley();
        $('#date_form').parsley();
        $('#time_form').parsley();
        $('#cost_form').parsley();
        $('#gender_form').parsley();
        $('#target_form').parsley();

        // Disable image upload
        // $('#image').prop("disabled", true);
        $('#image').prop("required", false);
        set_switch_button(true);
        set_ad_img(result.ad_img);
        set_img_src(result.img_src);
        set_img_name(result.img_name);
        __index(index);

        // Populate exist data into input form.
        $('#ad_name').val(result.name);
        $('#ad_budget').val(result.budget);
        $('#date_from').val(result.start_date);
        $('#ad_duration').val(result.duration);
        $('#ad_category').val(result.category);
        $('#category_data').val(result.category);
        $('#total_click').val(result.total_clicks);
        $('#click_per_day').val(result.click_per_day);
        $('#custom_time').val(result.custom_cost.type);
        $('#cost_model').val(result.cost_model);
        $('#gender').val(result.gender);
        $('#b1a').prop({checked: result.age.group_a});
        $('#b2a').prop({checked: result.age.group_b});
        $('#b3a').prop({checked: result.age.group_c});
        $('#b4a').prop({checked: result.age.group_d});
        $('#region_type').val(result.region.type);

        if(result.region.type === "national") {
            $('#region_data').prop("disabled", true);
            $('#region_data').prop("required", false);
        }

        if(result.region.data.length > 0) {
            $("#region_data").prop("disabled", false);
            for(let i = 0; i < result.region.data.length; ++i) {
                let option = [];
                option[i] = new Option(result.region.data[i], result.region.data[i]);
                option[i].selected = true;

                $("#region_data").append(option).trigger("change");
            }
        }

        if(result.extra_category.length > 0) {
            for(let i = 0; i < result.extra_category.length; ++i) {
                let option = [];
                option[i] = new Option(result.extra_category[i], result.extra_category[i]);
                option[i].selected = true;

                $("#category_data").append(option).trigger("change");
            }
        }

        if(result.custom_cost.type === "normal") {
            $('#start_hour').prop("disabled", true);
            $('#end_hour').prop("disabled", true);
            set_start_hour(result.custom_cost.start_hour);
            set_end_hour(result.custom_cost.end_hour);
        } else {
            $('#start_hour').prop("disabled", false);
            $('#end_hour').prop("disabled", false);
            set_start_hour(result.custom_cost.start_hour);
            set_end_hour(result.custom_cost.end_hour);
        }

        $('#modalCreateAd').modal({
            backdrop: 'static',
            keyboard: false
        });

        $('#ad').parsley();
        $("#modalCreateAd").modal("show");
    };

    const handleAdUpdate = () => {
        if(
            $('#ad_name').parsley().isValid() === false ||
            $('#ad_budget').parsley().isValid() === false ||
            $('#ad_category').parsley().isValid() === false ||
            $('#image').parsley().isValid() === false ||
            $('#ad_duration').parsley().isValid() === false ||
            $('#custom_cost').parsley().isValid() === false ||
            $('#custom_time').parsley().isValid() === false ||
            $('#date_from').parsley().isValid() === false ||
            $('#gender').parsley().isValid() === false ||
            $('#region_type').parsley().isValid() === false ||
            $('#region_type').parsley().isValid() === true && $('#region_data').parsley().isValid() === false
        ) {
            return null;
        }

        $("#modalCreateAd").modal("hide");
        $(".modal-backdrop").remove();

        let filteredArray = ads.filter(item => item.index !== _index);
        let raw_filteredArray = ads_raw.filter(item => item.index !== _index);
        let imageArray = upload_image.filter(item => item.index !== _index);
        let findDataArray = ads.filter(item => item.index === _index);

        let data_raw = {
            index: img_src,
            name : $('#ad_name').val(),
            img_src: img_src,
            budget : $('#ad_budget').val(),
            duration : $('#ad_duration').val(),
            category : $('#ad_category').val(),
            start_date : $('#date_from').val(),
            custom_cost : {
                type : $('#custom_time').val(),
                start_hour : $('#start_hour').val(),
                end_hour : $('#end_hour').val()
            },
            cost_model : $('#custom_cost').val(),
            total_clicks : $('#total_click').val(),
            click_per_day : $('#click_per_day').val(),
            uri : $('#target_url').val(),
            gender : $('#gender').val(),
            extra_category: $('#category_data').val(),
            age : {
                group_a : $('#b1a').is(':checked'),
                group_b : $('#b2a').is(':checked'),
                group_c : $('#b3a').is(':checked'),
                group_d : $('#b4a').is(':checked')
            },
            region : {
                type : $('#region_type').val(),
                data : $('#region_data').val()
            }
        };

        let data = {
            index: img_src,
            img_preview: img_preview || findDataArray[0].img_preview,
            ad_img: ad_img,
            name : $('#ad_name').val(),
            img_name: img_name,
            img_src: img_src,
            budget : $('#ad_budget').val(),
            duration : $('#ad_duration').val(),
            category : $('#ad_category').val(),
            start_date : $('#date_from').val(),
            custom_cost : {
                type : $('#custom_time').val(),
                start_hour : $('#start_hour').val(),
                end_hour : $('#end_hour').val()
            },
            cost_model : $('#custom_cost').val(),
            total_clicks : $('#total_click').val(),
            click_per_day : $('#click_per_day').val(),
            uri : $('#target_url').val(),
            gender : $('#gender').val(),
            extra_category: $('#category_data').val(),
            age : {
                group_a : $('#b1a').is(':checked'),
                group_b : $('#b2a').is(':checked'),
                group_c : $('#b3a').is(':checked'),
                group_d : $('#b4a').is(':checked')
            },
            region : {
                type : $('#region_type').val(),
                data : $('#region_data').val()
            }
        };

        filteredArray.push(data);
        raw_filteredArray.push(data_raw);

        imageArray.push({
            index: ads.length + 1,
            raw: ad_img
        });

        set_ads_raw(raw_filteredArray);
        set_ads(filteredArray);
        set_upload_image(imageArray);

        clearAdForm();
    };

    const limitBudget = () => {
        const total = ads.reduce((acc, item) => acc+= parseInt(item.budget), 0);
        if(total <= 200) {
            return 200;
        } else {
            return total;
        }
    };

    const handleAdValidate = () => {
        if(ads.length === 0) {
            return cogoToast.error("Please add one or more ads before you proceed.");
        }

        if(budget_balance() !== 0) {
            return cogoToast.error("You still have balance left. Please add more ads.", option);
        }

        $('.nav-line .active').parent().next('li').find('a').trigger('click');
    };

    const handleStartHourSelected = (event) => {
        set_start_hour(event.target.value);
    };

    const handleEndHourSelected = (event) => {
        set_end_hour(event.target.value);
    };

    const start_hours = Array(24 - 1).fill(1).map((x, y) => x + y);
    const end_hours = Array(24 - parseInt(start_hour)).fill(parseInt(start_hour) + 1).map((x, y) => x + y);

    let renderAd = () => {
        if(ads.length === 0) {
            return(
                <div className="row row-sm">
                    <div className="container d-flex justify-content-center ht-350">
                        <div
                            className="mx-wd-300 wd-sm-450 ht-100p d-flex flex-column align-items-center justify-content-center">
                            <div className="wd-80p wd-sm-300 mg-b-15 tx-center"><i className="wd-60 ht-60 tx-color-03" data-feather="image"/></div>
                            <p className="tx-color-03 mg-b-30 tx-center">Accepted image files: PNG, JPEG, GIF.
                                <br/>Resolution Min: 1280x720 upward<br/>Maximum file size: 1MB</p>
                            <button className="btn btn-brand-02 outline-none" onClick={handleModal.bind()} data-toggle="modal"><i data-feather="plus"/> Create</button>
                        </div>
                    </div>
                </div>
            )
        } else {

            const renderAdItems = ads.map((result, index) => {
                return(
                    <div key={index} className="col-lg-4 col-md-6">
                        <div className="card card-body pd-t-8 pd-l-8 pd-b-8 mg-b-20 pd-b-4">
                            <div className="media">
                                <img src={result.img_preview} className="ht-120 wd-85 rounded mg-r-10" alt=""/>
                                <div className="media-body">
                                    <h6 className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-bold mg-b-8">{result.name}</h6>
                                    <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-normal mg-b-8">
                                        <span className="tx-semibold">Budget:</span> {result.budget}</h6>
                                    <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-normal mg-b-8">
                                        <span className="tx-semibold">Click:</span> {result.total_clicks}</h6>
                                    <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-normal mg-b-8">
                                        <span className="tx-semibold">Cost Model:</span> {result.cost_model}</h6>
                                    <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-normal mg-b-5">
                                        <span className="tx-semibold">Duration:</span> {result.duration}</h6>
                                    <nav className="nav nav-icon-only">
                                        <NavLink to="#" className="nav-link" onClick={
                                            handleDeleteAd.bind(null, result.budget, result.index)
                                        }><i className="fa fa-trash-alt"/> Delete</NavLink>
                                        <NavLink to="#" className="nav-link" onClick={
                                            handleEditAdModal.bind(null, result, result.index)
                                        }
                                        ><i className="fa fa-pencil"/> Edit</NavLink>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            });

            return(
                <div className="row row-sm">
                    {renderAdItems}
                    <div className="col-lg-4 col-md-6 container d-flex justify-content-center">
                        <div
                            className="mx-wd-300 wd-sm-450 ht-100p d-flex flex-column align-items-center justify-content-center">
                            <p className="tx-color-03 mg-b-10 tx-center">Accepted image files: PNG, JPEG, GIF.<br/>Resolution
                                Min: 1280x720 upward<br/>Maximum file size: 1MB</p>
                            <button className="btn btn-sm btn-brand-02 outline-none" onClick={handleModal.bind()} data-toggle="modal"><i data-feather="plus"/> Create</button>
                        </div>
                    </div>
                </div>
            );
        }
    };

    let renderSwitchButton = () => {
        if(switch_button == true) {
            return(
                <button className="btn btn-success float-right" onClick={handleAdUpdate.bind()}>Update</button>
            );
        }

        return(
            <button className="btn btn-success float-right" onClick={handleCreateAd.bind()}><i className="fa fa-check-circle"/> Finish</button>
        );
    };

    let renderProceedButton = () => {
        if(stateReady == true) {
            return(
                <button className="btn btn-brand-02 float-right" disabled>
                    <span className="spinner-border spinner-border-sm mg-r-10" aria-hidden="true"/>Please Wait</button>
            )
        } else {
            return(
                <button className="btn btn-brand-01 float-right" onClick={handleUploadAd.bind()}>Proceed <i className="ion-md-arrow-forward"/></button>
            );
        }
    };

    let budget_balance = () => {
        const total = ads.reduce((acc, item) => acc += parseInt(item.budget), 0);
        return parseInt($('#budget').val()) - total + parseInt(reserve_balance);
    }

    return (
        <div className="content ht-100v pd-0">
            <div className="content-body ht-100v pd-b-0">
                <div className="container container pd-x-0 pd-lg-x-10 pd-xl-x-0">
                    <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                        <div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                    <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Create</li>
                                </ol>
                            </nav>
                            <h4 className="mg-b-0 tx-spacing--1">Create Campaign</h4>
                        </div>
                    </div>
                    <div className="card card-body pd-x-25 pd-sm-x-30 pd-t-15 pd-sm-t-20 pd-b-15 pd-sm-b-20">
                        <div className="nav-wrapper mg-b-20 tx-13">
                            <div>
                                <ul id="campaign_basic" className="nav nav-line nav-fill tx-medium nav-disabled">
                                    <li className="nav-item"><a href="#basic" className="nav-link active" data-toggle="tab"><i className="ion-md-information-circle-outline"/> Basic</a></li>
                                    <li className="nav-item"><a href="#create_ads" className="nav-link" data-toggle="tab"><i className="ion-md-document"/> Create Ads</a></li>
                                    <li className="nav-item"><a href="#payment" className="nav-link" data-toggle="tab"><i className="ion-md-checkmark-circle-outline"/> Confirm</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="tab-content">
                            <div id="basic" className="tab-pane fade active show">
                                <form id="_basic" className="parsley-style-1" data-parsley-validate noValidate>
                                    <h5 className="tx-semibold">Basic Information</h5>
                                    <section>
                                        {/*<p className="mg-b-20">Try the keyboard navigation by clicking arrow left or right!</p>*/}
                                        <div className="row row-sm">
                                            <div id="advertiserWrapper" className="form-group parsley-input col-sm-12">
                                                <label>Advertiser<span className="tx-danger">*</span></label>
                                                <select className="form-control wd-438 advertiser-select2" multiple="multiple"
                                                        data-parsley-class-handler="#advertiserWrapper"
                                                        data-parsley-errors-container="#advertiserWrapper"
                                                        id="advertiser"
                                                        required>
                                                </select>
                                            </div>
                                            <div id="cnWrapper" className="form-group parsley-input col-sm-12">
                                                <label>Name<span className="tx-danger">*</span></label>
                                                <input id="campaign" className="form-control"
                                                       name="campaign"
                                                       placeholder="Enter campaign name"
                                                       type="text"
                                                       autoComplete="off"
                                                       data-parsley-class-handler="#cnWrapper" required/>
                                            </div>
                                            <div id="bgWrapper" className="form-group parsley-input col-sm-12">
                                                <label>Budget<span className="tx-danger">*</span></label>
                                                <input id="budget" className="form-control" name="budget"
                                                       placeholder="Enter budget" type="number"
                                                       min={limitBudget()}
                                                       autoComplete="off"
                                                       data-parsley-class-handler="#bgWrapper" required/>
                                            </div>
                                        </div>
                                    </section>
                                    <hr/>
                                    <button className="btn btn-brand-01 float-right btnNextFront" onClick={handleNext.bind()}>Next <i className="ion-md-arrow-forward"/></button>
                                </form>
                            </div>
                            <div id="create_ads" className="tab-pane fade">
                                <h5 className="tx-semibold">Create Ads</h5>
                                <section>
                                    <p className="tx-13 tx-color-02 mg-b-0">Proposed Budget: R{proposed_balance()}</p>
                                    <p className="tx-13 text-danger mg-b-20">Budget Balance: R{budget_balance()}</p>
                                    {renderAd()}
                                </section>
                                <hr/>
                                <button className="btn btn-dark float-left btnPrevious" onClick={handlePrevious.bind()}><i className="ion-md-arrow-back"/> Back</button>
                                <button className="btn btn-brand-01 float-right btnNextAd" onClick={handleAdValidate.bind()}>Next <i className="ion-md-arrow-forward"/></button>
                            </div>
                            <div id="payment" className="tab-pane fade">
                                <h5 className="tx-semibold">Payment Details</h5>
                                <section>
                                    <div className="ht-100">
                                        <p className="mg-b-5">Campaign: {$('#campaign').val()}</p>
                                        <p className="mg-b-5">Budget: R{proposed_balance()}</p>
                                        <p className="mg-b-10">Upload Ads: {ads.length}</p>
                                    </div>
                                    <hr/>
                                    <div className="d-flex justify-content-end">
                                        <p className="mg-b-5"><strong>Subtotal:</strong> R{proposed_balance()}<br/>
                                            <strong>15% Discount:</strong> R0<br/>
                                            <strong>Total:</strong> R{proposed_balance()}</p>
                                    </div>
                                </section>
                                <hr/>
                                <button className="btn btn-dark float-left btnPrevious" disabled={previous} onClick={handlePrevious.bind()}><i className="ion-md-arrow-back"/> Back</button>
                                {renderProceedButton()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalCreateAd" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                    <div className="modal-content">
                        <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                            <div className="media align-items-center">
                                <div className="media-body">
                                    <a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close" onClick={clearAdForm.bind()}>
                                        <span aria-hidden="true">&times;</span>
                                    </a>
                                    <h4 id="ad_title" className="tx-18 tx-sm-20 mg-b-2">Create Ad</h4>
                                    <p className="tx-13 tx-color-02 mg-b-0">Proposed Budget: R{proposed_balance()}</p>
                                    <p className="tx-13 text-danger mg-b-0">Budget Balance: R{budget_balance()}</p>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                            <div className="nav-wrapper mg-b-20 tx-13">
                                <ul id="ad_pill" className="nav nav-line nav-fill tx-medium nav-disabled">
                                    <li className="nav-item"><a href="#step1" className="nav-link active" data-toggle="tab"><i className="ion-md-information-circle-outline"/> Basic</a></li>
                                    <li className="nav-item"><a href="#step2" className="nav-link" data-toggle="tab"><i className="ion-md-calendar"/> Date</a></li>
                                    <li className="nav-item"><a href="#step3" className="nav-link" data-toggle="tab"><i className="ion-md-stopwatch"/> Time</a></li>
                                    <li className="nav-item"><a href="#step4" className="nav-link" data-toggle="tab"><i className="ion-md-wallet"/> Cost</a></li>
                                    <li className="nav-item"><a href="#step5" className="nav-link" data-toggle="tab"><i className="ion-md-male"/> Gender</a></li>
                                    <li className="nav-item"><a href="#step6" className="nav-link" data-toggle="tab"><i className="ion-md-navigate"/> Target</a></li>
                                </ul>
                            </div>

                            <div className="tab-content">
                                <div id="step1" className="tab-pane fade active show">
                                    <form id="basic_form" className="parsley-style-1">
                                        <h6 className="tx-semibold">Basic Information</h6>
                                        <section>
                                            <p className="mg-b-20">Try the keyboard navigation by clicking arrow left or right!</p>
                                            <div id="adWrapper" className="form-group parsley-input">
                                                <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Ad Name</label>
                                                <input id="ad_name" type="text" className="form-control"
                                                       placeholder="Enter ad name"
                                                       autoComplete="off"
                                                       data-parsley-class-handler="#adWrapper" required/>
                                            </div>

                                            <div className="form-group">
                                                <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Select Images</label>
                                                <input id="image" type="file" className="form-control"
                                                       onChange={handleFileUpload.bind()}
                                                       data-parsley-filemaxmegabytes="1"
                                                       data-parsley-trigger="change"
                                                       data-parsley-filemimetypes="image/jpeg, image/png, image/gif, image/jpg"
                                                       />
                                            </div>

                                            <div id="budgetWrapper" className="form-group parsley-input">
                                                <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Allocate Budget</label>
                                                <input id="ad_budget" type="number" className="form-control"
                                                       placeholder="Enter budget required"
                                                       min={200}
                                                       max={budget_balance()}
                                                       autoComplete="off"
                                                       data-parsley-class-handler="#budgetWrapper" required/>
                                            </div>
                                            <hr/>
                                            <button className="btn btn-brand-01 float-right btnNextFront" onClick={handleAdBasicNext.bind()}>Next <i className="fa fa-arrow-alt-right"/></button>
                                        </section>
                                    </form>
                                </div>

                                <div id="step2" className="tab-pane fade">
                                    <form id="date_form" className="parsley-style-1">
                                        <h6 className="tx-semibold">Create Ads</h6>
                                        <section>
                                            <p className="mg-b-20">Try the keyboard navigation by clicking arrow left or right!</p>
                                            <div id="startWrapper" className="form-group parsley-input">
                                                <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Select Start Date</label>
                                                <input type="text"
                                                       id="date_from"
                                                       className="form-control"
                                                       placeholder="Select Start Date"
                                                       autoComplete="off"
                                                       data-parsley-class-handler="#startWrapper" required
                                                       readOnly
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Duration</label>
                                                <select className="custom-select"
                                                        id="ad_duration"
                                                        required>
                                                    <option disabled value="">Select</option>
                                                    {duration.map((result) => <option key={result.name} value={result.name}>{result.name}</option>)}
                                                </select>
                                            </div>
                                        </section>
                                        <hr/>
                                        <button className="btn btn-dark float-left btnPrevious" onClick={handleAdPrevious.bind()}><i className="fa fa-arrow-alt-left"/> Back</button>
                                        <button className="btn btn-brand-01 float-right btnNextAd" onClick={handleAdDateNext.bind()}>Next <i className="fa fa-arrow-alt-right"/></button>
                                    </form>
                                </div>

                                <div id="step3" className="tab-pane fade">
                                    <form id="time_form" className="parsley-style-1">
                                        <h6 className="tx-semibold">Customize Time</h6>
                                        <section>
                                            <p className="mg-b-20">The next and previous buttons help you to navigate through your content.</p>
                                            <div className="row row-sm">
                                                <div className="form-group col-12">
                                                    <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Custom Time</label>
                                                    <select className="custom-select"
                                                            id="custom_time"
                                                            onChange={handleCustomTimeSelected.bind()}
                                                            required>
                                                        <option value="" disabled>Select</option>
                                                        <option value="normal">Full Time</option>
                                                        <option value="custom">Custom Time</option>
                                                    </select>
                                                </div>

                                                <div className="form-group col-12">
                                                    <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Start Hour</label>
                                                    <select className="custom-select"
                                                            id="start_hour"
                                                            value={start_hour}
                                                            onChange={handleStartHourSelected.bind()}
                                                            required>
                                                        <option value="" disabled>Select</option>
                                                        {start_hours.map((result) => <option key={result} value={result}>{result}</option>)}
                                                    </select>
                                                </div>

                                                <div className="form-group col-12">
                                                    <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">End Hour</label>
                                                    <select className="custom-select"
                                                            id="end_hour"
                                                            value={end_hour}
                                                            onChange={handleEndHourSelected.bind()}
                                                            required>
                                                        <option value="" disabled>Select</option>
                                                        {end_hours.map((result) => <option key={result} value={result}>{result}</option>)}
                                                    </select>
                                                </div>

                                                <div className="form-group col-12">
                                                    <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Category</label>
                                                    <select className="custom-select"
                                                            id="ad_category"
                                                            onChange={handleCategorySelected.bind()}
                                                            required>
                                                        <option value="" disabled>Select</option>
                                                        {interests.map((result) => <option key={result.name} value={result.name}>{result.name}</option>)}
                                                    </select>
                                                </div>

                                                <div className="form-group col-12">
                                                    <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Additional Category (Optional)</label>
                                                    <select className="form-control category-select2" multiple="multiple"
                                                            data-parsley-class-handler="#categoryWrapper"
                                                            id="category_data">
                                                    </select>
                                                </div>
                                            </div>
                                            <hr/>
                                            <button className="btn btn-dark float-left btnPrevious" onClick={handleAdPrevious.bind()}><i className="fa fa-arrow-alt-left"/> Back</button>
                                            <button className="btn btn-brand-01 float-right btnNextAd" onClick={handleAdTimeNext.bind()}>Next <i className="fa fa-arrow-alt-right"/></button>
                                        </section>
                                    </form>
                                </div>

                                <div id="step4" className="tab-pane fade">
                                    <form id="cost_form" className="parsley-style-1">
                                        <h6 className="tx-semibold">Customize Cost</h6>
                                        <section>
                                            <p className="mg-b-20">The next and previous buttons help you to navigate through your content.</p>
                                            <div className="row row-sm">
                                                <div className="form-group col-sm-12">
                                                    <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Custom Cost</label>
                                                    <select className="custom-select"
                                                            onChange={handleCustomCostSelected.bind()}
                                                            id="custom_cost"
                                                            required>
                                                        <option value="cpe">CPE - {cpe_price}</option>
                                                        <option value="cpc">CPC - {cpc_price}</option>
                                                        <option value="cpa">CPA - {cpa_price}</option>
                                                    </select>
                                                </div>

                                                <div className="form-group col-sm-12">
                                                    <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Total Clicks</label>
                                                    <input type="number"
                                                           className="form-control"
                                                           id="total_click"
                                                           placeholder="0"
                                                           readOnly
                                                    />
                                                </div>

                                                <div className="form-group col-sm-12">
                                                    <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Clicks Per Day</label>
                                                    <input type="number"
                                                           id="click_per_day"
                                                           className="form-control"
                                                           placeholder="0"
                                                           readOnly
                                                    />
                                                </div>

                                                <div id="urlWrapper" className="form-group col-sm-12 parsley-input">
                                                    <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Website Link</label>
                                                    <input type="url" className="form-control"
                                                           id="target_url"
                                                           data-parsley-type='url'
                                                           placeholder="Not Applicable"
                                                           autoComplete="off"
                                                           data-parsley-class-handler="#urlWrapper"
                                                    />
                                                </div>
                                            </div>
                                        </section>
                                        <hr/>
                                        <button className="btn btn-dark float-left btnPrevious" onClick={handleAdPrevious.bind()}><i className="fa fa-arrow-alt-left"/> Back</button>
                                        <button className="btn btn-brand-01 float-right btnNextAd" onClick={handleAdCostNext.bind()}>Next <i className="fa fa-arrow-alt-right"/></button>
                                    </form>
                                </div>

                                <div id="step5" className="tab-pane fade">
                                    <form id="gender_form" className="parsley-style-1">
                                        <h6 className="tx-semibold">Demographics</h6>
                                        <section>
                                            <p className="mg-b-20">The next and previous buttons help you to navigate through your content.</p>
                                            <div className="row row-sm">
                                                <div className="form-group col-sm-12">
                                                    <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Select Target Gender</label>
                                                    <select className="custom-select"
                                                            id="gender"
                                                            required>
                                                        <option value="" disabled>Select</option>
                                                        <option value="male">Male</option>
                                                        <option value="female">Female</option>
                                                        <option value="both">Both</option>
                                                    </select>
                                                </div>

                                                <div className="form-group col-sm-12">
                                                    <div data-label="Age Group" className="df-example mg-b-5">
                                                        <div id="cbWrapper2" className="parsley-checkbox pos-relative">
                                                            <div className="custom-control custom-checkbox">
                                                                <input className="custom-control-input" type="checkbox" name="age[]"
                                                                       data-parsley-mincheck="1"
                                                                       data-parsley-class-handler="#cbWrapper2"
                                                                       data-parsley-errors-container="#cbErrorContainer2"
                                                                       required
                                                                       data-parsley-multiple="age" id="b1a"/>
                                                                <label className="custom-control-label" htmlFor="b1a">18 - 24</label>
                                                            </div>

                                                            <div className="custom-control custom-checkbox">
                                                                <input className="custom-control-input" type="checkbox"
                                                                       name="age[]"
                                                                       data-parsley-multiple="age"
                                                                       id="b2a"/>
                                                                <label className="custom-control-label" htmlFor="b2a">25 - 34</label>
                                                            </div>

                                                            <div className="custom-control custom-checkbox">
                                                                <input className="custom-control-input" type="checkbox"
                                                                       name="age[]"
                                                                       data-parsley-multiple="age"
                                                                       id="b3a"/>
                                                                <label className="custom-control-label" htmlFor="b3a">35 - 44</label>
                                                            </div>

                                                            <div className="custom-control custom-checkbox">
                                                                <input className="custom-control-input" type="checkbox"
                                                                       name="age[]"
                                                                       data-parsley-multiple="age"
                                                                       id="b4a"/>
                                                                <label className="custom-control-label" htmlFor="b4a">45 and above</label>
                                                            </div>

                                                            <div id="cbErrorContainer2" className="wd-100p pos-absolute b-0 l-0"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <hr/>
                                        <button className="btn btn-dark float-left btnPrevious" onClick={handleAdPrevious.bind()}><i className="fa fa-arrow-alt-left"/> Back</button>
                                        <button className="btn btn-brand-01 float-right btnNextAd" onClick={handleAdGenderNext.bind()}>Next <i className="fa fa-arrow-alt-right"/></button>
                                    </form>
                                </div>

                                <div id="step6" className="tab-pane fade">
                                    <form id="target_form" className="parsley-style-1">
                                        <h6 className="tx-semibold">Target Advert</h6>
                                        <section>
                                            <p className="mg-b-20">The next and previous buttons help you to navigate through your content.</p>
                                            <div className="row row-sm">
                                                <div className="form-group col-sm-12">
                                                    <div className="d-flex justify-content-between mg-b-5">
                                                        <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Select Region</label>
                                                        <Link to="#" className="tx-11" data-container="body" data-toggle="popover" data-placement="right" data-content={region_info}>Learn More</Link>
                                                    </div>
                                                    <select className="custom-select"
                                                            id="region_type"
                                                            onChange={handleRegionType.bind()}
                                                            required>
                                                        <option value="" disabled>Select</option>
                                                        <option value="national">National</option>
                                                        <option value="region">Provinces/State</option>
                                                        <option value="city">City</option>
                                                        <option value="suburb">Suburb/Townships</option>
                                                    </select>
                                                </div>

                                                <div className="form-group col-md-12">
                                                    <div className="d-flex justify-content-between mg-b-5">
                                                        <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Enter Target Area(s)</label>
                                                        <Link to="/statistics" target="_blank" className="tx-11">Statistics</Link>
                                                    </div>
                                                    <div>
                                                        <select className="form-control wd-438 ad-select2" multiple="multiple"
                                                                data-parsley-class-handler="#regionWrapper"
                                                                id="region_data"
                                                        >
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <hr/>
                                        <button className="btn btn-dark float-left btnPrevious" onClick={handleAdPrevious.bind()}><i className="fa fa-arrow-alt-left"/> Back</button>
                                        {renderSwitchButton()}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default CreateAd;
