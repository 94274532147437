import user from "../data/user";

const initialState = {
    user_data: user
};

export default function(state = initialState, action) {
    if (action.type === 'USER_SEARCH') {
        return {
            user_data: action.payload
        };
    } else {
        return state;
    }
}
