import axios from 'axios';
import { GET_AD_REPORT_URL } from '../../api';

export const getAdReport = (ad_data, start_date, end_date) => {
    return axios.post(GET_AD_REPORT_URL, {ad_data, start_date, end_date}).then((response) => {
        return {
            error: false,
            data: response.data
        }
    }).catch(() => {
        return {
            error: true,
            data: null
        }
    });
};
