import moment from "moment";

const initialState = {
    advertiser_data: {
        total: 0,
        active: 0,
        inactive: 0,
        today: 0,
        current: moment().format("Do MMM, YYYY")
    },
    loading: false
};

export default function(state = initialState, action) {
    switch(action.type) {
        case 'ADVERTISER_STATISTICS':
            return {
                ...state,
                advertiser_data: action.payload
            };

        case 'GET_CAMPAIGN_AD':
            return {
                ...state,
                campaign_ad: action.payload,
                loading: false
            };

        case 'CLEAR_CAMPAIGN_AD':
            return {
                ...state,
                campaign_ad: null
            };

        default:
            return state;
    }
}
