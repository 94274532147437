import React, {useEffect, useState} from 'react';
import {GET_ACCESS_CONTROL_URL} from '../../../api';
import 'datatables.net';
import 'datatables.net-responsive';
import $ from "jquery";
import {Link} from "react-router-dom";
import cogoToast from "cogo-toast";
import {deleteAmin, addAdminUser} from "../../../redux/actions";
import {capitalize} from "../../../utils/capitalize";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";

const AccessControl = () => {

    document.title = "Access Control";

    const dispatch = useDispatch();

    const id = useSelector((state) => state.auth.user.sub);
    const [stateReady, setStateReady] = useState(false);

    const token = localStorage.getItem('jwtToken');

    useEffect(() => {

        var table = $('#admin').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting admin list</p>'
            },
            "searching": true,
            "lengthMenu": [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(GET_ACCESS_CONTROL_URL, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        pageSize: data.start,
                        format: 'json',
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success : function(res) {
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: res.data
                        });
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data.name
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data.designation
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return capitalize(data.type)
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return moment(data.last_login.date).format("Do MMM, YYYY");
                    },
                    "targets": 3
                },
                {
                    "render": function () {
                        return `<nav class="nav nav-icon-only"><a href="#" class="nav-link outline-none show_modal"><i class="fa fa-eye"></i> View</a><a href="#" class="nav-link delete_user"><i class="fa fa-trash-alt"></i> Delete</a></nav>`
                },
                    "targets": 4
                }
            ]
        });

        $('#admin').on('click', '.delete_user', function() {
            let extract_td = table.row($(this).closest('tr')).data();

            const error = {
                position: "top-right",
                hideAfter: 5
            };

            if(extract_td.raw._id === id) {
                cogoToast.error('Sorry, this logged account cannot be deleted.', error);
                return false;
            }

            const options = {
                position: "top-right",
                hideAfter: 0
            };

            let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

            deleteAmin(extract_td.raw._id).then((result) => {
                hide();
                const _options = {
                    position: "top-right"
                };

                if(result.error === false) {
                    $('#admin').DataTable().ajax.reload(null, false);
                    cogoToast.success(result.data, _options);
                } else {
                    cogoToast.error(result.data, _options);
                }
            });

            return false;
        });
    }, []);

    const handleSubmit = () => {
        if(
            $('#name').parsley().isValid() === false ||
            $('#email').parsley().isValid() === false ||
            $('#designation').parsley().isValid() === false ||
            $('#password').parsley().isValid() === false ||
            $('#b1a').is(':checked') === false &&
            $('#b2a').is(':checked') === false &&
            $('#b3a').is(':checked') === false &&
            $('#b4a').is(':checked') === false &&
            $('#b5a').is(':checked') === false &&
            $('#b6a').is(':checked') === false &&
            $('#b7a').is(':checked') === false &&
            $('#b8a').is(':checked') === false)
        {
            return false;
        }

        const options = {
            position: "top-right",
            hideAfter: 0
        };

        let type = "standard";

        let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

        if($('#b1a').is(':checked') === true && $('#b2a').is(':checked') === true && $('#b3a').is(':checked') === true && $('#b4a').is(':checked') === true && $('#b5a').is(':checked') === true && $('#b6a').is(':checked') === true && $('#b7a').is(':checked') === true && $('#b8a').is(':checked') === true) {
            type = "admin";
        }

        let data = {
            name: $('#name').val(),
            email: $('#email').val(),
            designation: $('#designation').val(),
            password: $('#password').val(),
            type: type,
            access_privilege: {
                user: $('#b1a').is(':checked'),
                transaction: $('#b2a').is(':checked'),
                mobile: $('#b3a').is(':checked'),
                access_control: $('#b4a').is(':checked'),
                advertiser: $('#b5a').is(':checked'),
                edit: $('#b6a').is(':checked'),
                delete: $('#b7a').is(':checked'),
                create: $('#b8a').is(':checked')
            }
        };

        addAdminUser(data).then((result) => {
            hide();
            const _options = {
                position: "top-right"
            };

            if(result.error !== true) {
                handleResetForm();
                $("#modalAddUser").modal("hide");
                $(".modal-backdrop").remove();
                $('#admin').DataTable().ajax.reload();
                return cogoToast.success(result.data, _options);
            }

            cogoToast.error(result.data, _options);
        });

    };

    const handleResetForm = () => {
        $('#name').val("");
        $('#email').val("");
        $('#designation').val("");
        $('#password').val("");
        $('#b1a').prop({checked: false});
        $('#b2a').prop({checked: false});
        $('#b3a').prop({checked: false});
        $('#b4a').prop({checked: false});
        $('#b5a').prop({checked: false});
        $('#b6a').prop({checked: false});
        $('#b7a').prop({checked: false});
        $('#b8a').prop({checked: false});
        $('#new_user').parsley().destroy();
    };

    const handleShowModal = () => {
        $('#new_user').parsley();

        $("#new_user").on('submit', function(e){
            e.preventDefault();
        });

        $("#modalAddUser").modal("show");
    };

    const reloadTable = () => {
        $('#admin').DataTable().ajax.reload();
    };

    let renderButton = () => {
        if(stateReady === true) {
            return(
                <button disabled className="btn btn-brand-02 btn-block">
                    <span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>
                    Please Wait</button>
            )
        } else {
            return(
                <button className="btn btn-brand-02 btn-block">Create Account</button>
            );
        }
    };

    return (
        <div className="content-body ht-100v pd-b-0">
            <div className="container pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Access Control</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Access Control</h4>
                    </div>
                    <div className="d-none d-md-block">
                        <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5 outline-none" onClick={handleShowModal.bind()}><i className="wd-10 mg-r-5 fa fa-user-plus"></i> Add New</button>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">User List</h6>
                                <div className="d-flex tx-18">
                                    <Link to="#" onClick={reloadTable.bind()} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></Link>
                                    {/*<a href="" className="link-03 lh-0 mg-l-10"><i className="icon ion-md-more"></i></a>*/}
                                </div>
                            </div>
                            <div className="card-body">
                                <table id="admin" className="table">
                                    <thead>
                                    <tr>
                                        <th className="wd-20p">Name</th>
                                        <th className="wd-20p">Designation</th>
                                        <th className="wd-10p">Type</th>
                                        <th className="wd-20p">Last Login</th>
                                        <th className="wd-20p">Actions</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="modalAddUser" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                            <div className="modal-content">
                                <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                    <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </a>
                                    <div className="media-body"><a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" onClick={handleResetForm.bind()} aria-label="Close"><span aria-hidden="true">×</span></a><h4 className="tx-18 tx-sm-20 mg-b-2">Add New</h4><p className="tx-13 tx-color-02 mg-b-0">Create user to have access to the console</p></div>
                                </div>
                                <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                    <form id="new_user" className="parsley-style-1" data-parsley-validate="true" noValidate onSubmit={handleSubmit.bind()}>
                                        <div id="nameWrapper" className="form-group parsley-input">
                                            <label>Full Name<span className="tx-danger">*</span></label>
                                            <input id="name" type="text" className="form-control"
                                                   placeholder="Enter full name"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#nameWrapper" required/>
                                        </div>

                                        <div id="emailWrapper" className="form-group parsley-input">
                                            <label>Email Address<span className="tx-danger">*</span></label>
                                            <input id="email" type="email" className="form-control"
                                                   placeholder="Enter valid email address"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#emailWrapper" required/>
                                        </div>

                                        <div id="designationWrapper" className="form-group parsley-input">
                                            <label>Designation<span className="tx-danger">*</span></label>
                                            <input id="designation" type="text" className="form-control"
                                                   placeholder="Enter designation"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#designationWrapper" required/>
                                        </div>

                                        <div id="passwordWrapper" className="form-group parsley-input">
                                            <label>Password<span className="tx-danger">*</span></label>
                                            <input id="password" type="password" className="form-control"
                                                   placeholder="Enter new passowrd"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#passwordWrapper" required/>
                                        </div>

                                        <div className="form-group">
                                            <div data-label="Access Privilege" className="df-example mg-b-5">
                                                <div id="cbWrapper2" className="parsley-checkbox pos-relative">
                                                    <div className="custom-control custom-checkbox">
                                                        <input className="custom-control-input" type="checkbox" name="_form[]"
                                                               data-parsley-mincheck="1"
                                                            // onChange={handle_group_a.bind()}
                                                               data-parsley-class-handler="#cbWrapper2"
                                                               data-parsley-errors-container="#cbErrorContainer2"
                                                               required
                                                               data-parsley-multiple="_form" id="b1a"/>
                                                        <label className="custom-control-label" htmlFor="b1a">Show User</label>
                                                    </div>

                                                    <div className="custom-control custom-checkbox">
                                                        <input className="custom-control-input" type="checkbox"
                                                               name="_form[]"
                                                               data-parsley-multiple="_form"
                                                            // onChange={handle_group_b.bind()}
                                                               id="b2a"/>
                                                        <label className="custom-control-label" htmlFor="b2a">Show Transaction</label>
                                                    </div>

                                                    <div className="custom-control custom-checkbox">
                                                        <input className="custom-control-input" type="checkbox"
                                                               name="_form[]"
                                                               data-parsley-multiple="_form"
                                                            // onChange={handle_group_c.bind()}
                                                               id="b3a"/>
                                                        <label className="custom-control-label" htmlFor="b3a">Show Mobile</label>
                                                    </div>

                                                    <div className="custom-control custom-checkbox">
                                                        <input className="custom-control-input" type="checkbox"
                                                               name="_form[]"
                                                               data-parsley-multiple="_form"
                                                            // onChange={handle_group_d.bind()}
                                                               id="b4a"/>
                                                        <label className="custom-control-label" htmlFor="b4a">Show Access Control</label>
                                                    </div>

                                                    <div className="custom-control custom-checkbox">
                                                        <input className="custom-control-input" type="checkbox"
                                                               name="_form[]"
                                                               data-parsley-multiple="_form"
                                                            // onChange={handle_group_d.bind()}
                                                               id="b5a"/>
                                                        <label className="custom-control-label" htmlFor="b5a">Show Advertiser</label>
                                                    </div>

                                                    <div className="custom-control custom-checkbox">
                                                        <input className="custom-control-input" type="checkbox"
                                                               name="_form[]"
                                                               data-parsley-multiple="_form"
                                                            // onChange={handle_group_d.bind()}
                                                               id="b6a"/>
                                                        <label className="custom-control-label" htmlFor="b6a">Edit User, Adveriser & Campaign</label>
                                                    </div>

                                                    <div className="custom-control custom-checkbox">
                                                        <input className="custom-control-input" type="checkbox"
                                                               name="_form[]"
                                                               data-parsley-multiple="_form"
                                                            // onChange={handle_group_d.bind()}
                                                               id="b7a"/>
                                                        <label className="custom-control-label" htmlFor="b7a">Delete User, Adveriser & Campaign</label>
                                                    </div>

                                                    <div className="custom-control custom-checkbox">
                                                        <input className="custom-control-input" type="checkbox"
                                                               name="_form[]"
                                                               data-parsley-multiple="_form"
                                                            // onChange={handle_group_d.bind()}
                                                               id="b8a"/>
                                                        <label className="custom-control-label" htmlFor="b8a">Create Adveriser & Campaign</label>
                                                    </div>

                                                    <div id="cbErrorContainer2" className="wd-100p pos-absolute b-0 l-0"/>
                                                </div>
                                            </div>
                                        </div>

                                        {renderButton()}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AccessControl;
