import cogoToast from "cogo-toast";

const toast = (message, boolean) => {
    const option = {
        hideAfter: 3
    };

    if(boolean) {
        cogoToast.success(message, option);
    } else {
        cogoToast.error(message, option);
    }
};

export default toast;
