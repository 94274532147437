import React from 'react';
import Logo from "../../../assets/img/logo.png";
import {Mail, Printer} from 'react-feather';
import {decodeQueryParameter} from "../../../utils/url";

const Invoice = (props) => {

    const docs = decodeQueryParameter(props.match.params.encoded.replace(/'/g, ""));

    let renderItems = () => {
        return docs.campaign.ads.map((result, index) => {
            return(
                <tr key={index}>
                    <td className="tx-nowrap">{result.name}</td>
                    <td className="d-none d-sm-table-cell tx-color-03">
                        Cost Model: {result.cost_model}<br/>
                        Duration: {result.duration} Days<br/>
                        Budget: R{result.budget}
                    </td>
                    <td className="tx-center">{result.total_clicks}</td>
                    <td className="tx-right">R0.20</td>
                    <td className="tx-right">{result.budget}</td>
                </tr>
            );
        });
    };

    return(
        <div className="content content-body ht-100v pd-b-0">
            <div>
                <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
                    <div className="d-sm-flex align-items-center justify-content-between">
                        <div>
                            <h4 className="mg-b-5">Invoice #ADZ{docs.pf_paymentId}</h4>
                            <p className="mg-b-0 tx-color-03">{docs.dateCreated}</p>
                        </div>
                        <div className="mg-t-20 mg-sm-t-0">
                            <button className="btn btn-primary hide-print" onClick={() => {window.print()}}><Printer/> Print</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content tx-13">
                <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
                    <div className="row">
                        <div className="col-sm-6">
                            <label className="tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03">Billed From</label>
                            <br/><img src={Logo} className="ht-60 mg-b-6"/>
                            <h6 className="tx-18 mg-b-10">AdzLok</h6>
                            <p className="mg-b-0">2 Western Service Rd, Wendywood<br/>Sandton, Johannesburg 2090
                            </p>
                            <p className="mg-b-0">Tel No: +27 87 135 8042</p>
                            <p className="mg-b-0">Email: invoice@adzlok.co.za</p>
                        </div>
                        <div className="col-sm-6 tx-right d-none d-md-block">
                            <label className="tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03">Invoice Number</label>
                            <h1 className="tx-normal tx-color-03 mg-b-5 tx-spacing--2">#ADZ{docs.pf_paymentId}</h1>
                            <h3 className="tx-medium tx-danger mg-b-10 tx-spacing--2">{docs.status}</h3>
                        </div>
                        <div className="col-sm-6 col-lg-8 mg-t-40 mg-sm-t-0 mg-md-t-40">
                            <label className="tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03">Billed To</label>
                            <h6 className="tx-15 mg-b-10">{docs.advertiser.business_name}</h6>
                            <p className="mg-b-0">{docs.advertiser.address}</p>
                            <p className="mg-b-0">Tel No: {docs.advertiser.contact_number}</p>
                            <p className="mg-b-0">Email: {docs.advertiser.email}</p>
                        </div>
                        <div className="col-sm-6 col-lg-4 mg-t-40">
                            <label className="tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03">Invoice Information</label>
                            <ul className="list-unstyled lh-7">
                                <li className="d-flex justify-content-between">
                                    <span>Invoice Number</span>
                                    <span>#ADZ{docs.pf_paymentId}</span>
                                </li>
                                <li className="d-flex justify-content-between">
                                    <span>Issued Date</span>
                                    <span>{docs.dateCreated}</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="table-responsive mg-t-40">
                        <table className="table table-invoice bd-b">
                            <thead>
                            <tr>
                                <th className="wd-20p">Ad Name</th>
                                <th className="wd-40p d-none d-sm-table-cell">Description</th>
                                <th className="tx-center">Total Clicks</th>
                                <th className="tx-right">Unit Price</th>
                                <th className="tx-right">Amount</th>
                            </tr>
                            </thead>
                            <tbody>
                            {renderItems()}
                            </tbody>
                        </table>
                    </div>

                    <div className="row justify-content-between">
                        <div className="col-sm-6 col-lg-6 order-2 order-sm-0 mg-t-40 mg-sm-t-0">
                            <label className="tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03">Notes</label>
                            <p>Please note that the campaign may take up to 48hrs to be loaded<br/> on the AdzLok APP for us to review campaigns in line with our T&C's. </p>
                        </div>
                        <div className="col-sm-6 col-lg-4 order-1 order-sm-0">
                            <ul className="list-unstyled lh-7 pd-r-10">
                                <li className="d-flex justify-content-between">
                                    <span>Sub-Total</span>
                                    <span>{docs.amount}</span>
                                </li>
                                <li className="d-flex justify-content-between">
                                    <span>Tax (0%)</span>
                                    <span>R0.00</span>
                                </li>
                                <li className="d-flex justify-content-between">
                                    <span>Discount</span>
                                    <span>-R0.00</span>
                                </li>
                                <li className="d-flex justify-content-between">
                                    <strong>Total Due</strong>
                                    <strong>{docs.amount}</strong>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Invoice;
