import axios from "axios";
import {GET_INTEREST_URL, GET_PUBLISHER_AD_URL, PUBLISHER_ACTION_URL} from "../api";
import cogoToast from "cogo-toast";

export const getPublisherAd = (pageSize, category) => {
    return axios.post(GET_PUBLISHER_AD_URL, {pageSize, category}).then((response) => {
        return {
            data: response.data.publish,
            total: response.data.total,
            error: false
        }

    }).catch(() => {
        const option = {
            position: "top-right",
            hideAfter: 5
        };

        cogoToast.error("Error connecting to server.", option);
        return {
            data: null,
            error: true
        }
    });
};

export const actionPublisher = (id, type) => {
    return axios.post(PUBLISHER_ACTION_URL, {id, type}).then((response) => {
        return {
            data: response.data,
            error: false
        }

    }).catch(() => {
        const option = {
            position: "top-right",
            hideAfter: 5
        };

        cogoToast.error("Error connecting to server.", option);
        return {
            data: null,
            error: true
        }
    });
};

export const getInterest = () => {
    return axios.get(GET_INTEREST_URL).then((response) => {
        return {
            error: false,
            interest: response.data.interest
        }
    }).catch(() => {
        return {
            error: true,
            data: null
        }
    });
};
