import axios from 'axios';
import { GET_TOTAL_USER_STATISTICS_URL,
    UPDATE_USER_ACCOUNT_URL,
    DELETE_USER_URL,
    DELETE_ADMIN_URL,
    ADD_ADMIN_USER_URL,
    GRANT_MASTER_DEVICE_URL,
    ADD_COUNTRY_LIST_URL,
    DELETE_COUNTRY_URL,
    STATE_COUNTRY_URL,
    ACTION_NOTIFICATION,
    PUSH_NOTIFICATION
} from '../../api';
import cogoToast from "cogo-toast";
import errorHandler from "../../utils/errorHandler";
import toast from "../../utils/toast";

export const getUserStatistic = () => {
    return function(dispatch) {
        return axios.get(GET_TOTAL_USER_STATISTICS_URL).then((response) => {
            dispatch({
                type: 'USER_STATISTICS',
                payload: response.data
            })
        }).catch((err) => {
            const option = {
                hideAfter: 5
            };

            cogoToast.error(err.response.data, option);
        });
    }
};

export const deleteUser = (id) => {
    return axios.post(DELETE_USER_URL, {id}).then((response) => {
        return {
            data: response.data,
            error: false
        }
    }).catch((err) => {
        return {
            data: err.response.data,
            error: true
        }
    });
};

export const deleteAmin = (id) => {
    return axios.post(DELETE_ADMIN_URL, {id}).then((response) => {
        return {
            data: response.data,
            error: false
        }
    }).catch((err) => {
        return {
            data: err.response.data,
            error: true
        }
    });
};

export const deleteCountry = (id) => {
    return axios.post(DELETE_COUNTRY_URL, {id}).then((response) => {
        return {
            data: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            error: true
        }
    });
};

export const stateCountry = (id, state) => {
    return axios.post(STATE_COUNTRY_URL, {id, state}).then((response) => {
        return {
            data: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            error: true
        }
    });
};

export const sendPushNotification = (data) => {
    return axios.post(PUSH_NOTIFICATION, {
        topic: data.topic,
        title: data.title,
        raw_message: data.raw_message
    }).then((response) => {
        return {
            data: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            error: true
        }
    });
};

export const actionNotification = (data) => {
    return axios.post(ACTION_NOTIFICATION, {data}).then((response) => {
        return {
            data: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            error: true
        }
    });
}

export const addAdminUser = (data) => {
    return axios.post(ADD_ADMIN_USER_URL, {data}).then((response) => {
        return {
            data: response.data,
            error: false
        }
    }).catch((err) => {
        return {
            data: err.response.data.error,
            error: true
        }
    });
};

export const addCountry = (data, checked) => {
    return axios.post(ADD_COUNTRY_LIST_URL, {data, checked}).then((response) => {
        return {
            data: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            error: true
        }
    });
};

export const updateUserAccount = (phone, email, redeemAmount, walletAmount, emailVoid, country) => {
    return function(dispatch) {
        return axios.post(UPDATE_USER_ACCOUNT_URL, {phone, email, redeemAmount, walletAmount, emailVoid, country}).then((response) => {
            dispatch({
                type: 'USER_SEARCH',
                payload: response.data
            });
            return {
                data: response.data,
                error: false
            }
        }).catch((err) => {
            const option = {
                position: "top-right",
                hideAfter: 3
            };

            cogoToast.error(err.response.data.error, option);
            return {
                data: null,
                error: true
            }
        });
    }
};

export const grantMasterDevice = (user) => {
    return axios.post(GRANT_MASTER_DEVICE_URL, {user}).then((response) => {
        toast(response.data.payload, true);
        return false;
    }).catch((err) => {
        errorHandler(err);
        return false;
    });
};
