import React, {useEffect} from 'react';
import {GET_ADVERTISER_PAYMENT_URL} from '../../../api';
import formatNumber from "../../../utils/formatNumber";
import 'datatables.net';
import 'datatables.net-responsive';
import moment from 'moment';
import $ from "jquery";
import Search from "../Search";
import {Link} from "react-router-dom";
import {encodeQueryParameter} from "../../../utils/url";

const Payment = () => {

    document.title = "Payments";

    const token = localStorage.getItem('jwtToken');

    useEffect(() => {
        $('#payment').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Payment Report</p>'
            },
            "searching": true,
            "lengthMenu": [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(GET_ADVERTISER_PAYMENT_URL, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        pageSize: data.start,
                        format: 'json',
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success : function(res) {
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: res.data
                        });
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data.pf_paymentId
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return `R${formatNumber(data.amount)}`
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        try {
                            return data.description
                        } catch (e) {
                            return "No record"
                        }
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return moment(data.dateCreated).format("Do MMM, YYYY");
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        let encoded = encodeQueryParameter(data);
                        if(data.campaign !== null) {
                            return '<nav class="nav nav-icon-only"><a href="#/invoice/'+encoded+'" class="nav-link outline-none"><i class="ion-ios-attach tx-18"></i> Invoice</a> <a href="#/campaign-ad/'+data.campaign._id+'" class="nav-link outline-none"><i class="ion-md-eye tx-18"></i> View</a></nav>'
                        } else {
                            return '<nav class="nav nav-icon-only"><NavLink to="#" class="nav-link outline-none"><i class="ion-ios-eye-off tx-18"></i> No Actions</NavLink></nav>'
                        }
                    },
                    "targets": 4
                }
            ]
        });
    }, []);

    const reloadTable = () => {
        $('#payment').DataTable().ajax.reload();
    };

    return (
        <div className="content-body ht-100v pd-b-0">
            <div className="container pd-x-0">
                <div
                    className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Payments</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Payments</h4>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Payments List</h6>
                                <div className="d-flex tx-18">
                                    <Link to="#" onClick={reloadTable.bind()} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></Link>
                                    {/*<a href="" className="link-03 lh-0 mg-l-10"><i className="icon ion-md-more"></i></a>*/}
                                </div>
                            </div>
                            <div className="card-body">
                                <table id="payment" className="table">
                                    <thead>
                                    <tr>
                                        <th className="wd-15p">Receipt #</th>
                                        <th className="wd-15p">Amount</th>
                                        <th className="wd-40p">Description</th>
                                        <th className="wd-15p">Date</th>
                                        <th className="wd-20p">Actions</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Payment;
