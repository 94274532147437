import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import $ from "jquery";
import feather from "feather-icons";
import {logoutUser} from '../../request';
import PerfectScrollbar from 'perfect-scrollbar';
import {useDispatch, useSelector} from "react-redux";

const Header = () => {
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth.user);
    const [statistics, _statistics] = useState("nav-item");
    const [transaction, _transaction] = useState("nav-item");
    const [campaign, _campaign] = useState("nav-item");
    const [clients, _clients] = useState("nav-item");
    const [payment, _payment] = useState("nav-item");
    const [ad_publish, _ad_publish] = useState("nav-item");
    const [push_notification, _push_notification] = useState("nav-item");
    const [country, _country] = useState("nav-item");
    const [settings, _settings] = useState("nav-item with-sub active show");
    const [access_control, _access_control] = useState("nav-item");

    const onLogoutClick = (event) => {
        event.preventDefault();
        dispatch(logoutUser());
    };

    const handleActiveLink = (data, sub_data) => {
        _statistics("nav-item");
        _transaction("nav-item");
        _campaign("nav-item");
        _clients("nav-item");
        _payment("nav-item");
        _settings("nav-item with-sub");
        _ad_publish("nav-item");
        _access_control("nav-item");
        _push_notification("nav-item");
        _country("nav-item");
        switch (data) {
            case "dashboard":
                _statistics("nav-item active");
                break;
            case "transaction":
                _transaction("nav-item active");
                break;
            case "campaign":
                _campaign("nav-item active");
                break;
            case "clients":
                _clients("nav-item active");
                break;
            case "payment":
                _payment("nav-item active");
                break;
            case "settings":
                _settings("nav-item with-sub active show");
                switch (sub_data) {
                    case "ad_publish":
                        _ad_publish("nav-item active");
                        break;
                    default:
                        _ad_publish("nav-item active");
                        break;
                }
                break;
            case "push_notification":
                _push_notification("nav-item active");
                break;
            case "access_control":
                _access_control("nav-item active");
                break;
            case "country":
                _country("nav-item active");
                break;
            default:
                _statistics("nav-item");
                break;
        }
    };

    useEffect(() => {

        handleActiveLink("default", null);

        if(auth === false) {
            window.location.href = "/";
        }

        $(function () {
            'use strict';
            feather.replace();

            const asideBody = new PerfectScrollbar('.aside-body', {
                suppressScrollX: true
            });

            if($('.aside-backdrop').length === 0) {
                $('body').append('<div class="aside-backdrop"></div>');
            }

            var mql = window.matchMedia('(min-width:992px) and (max-width: 1199px)');

            function doMinimize(e) {
                if (e.matches) {
                    $('.aside').addClass('minimize');
                } else {
                    $('.aside').removeClass('minimize');
                }

                asideBody.update()
            }

            mql.addListener(doMinimize);
            doMinimize(mql);

            $('.aside-menu-link').on('click', function(e){
                e.preventDefault();

                if(window.matchMedia('(min-width: 992px)').matches) {
                    $(this).closest('.aside').toggleClass('minimize');
                } else {

                    $('body').toggleClass('show-aside');
                }

                asideBody.update()
            });

            $('.nav-aside .with-sub').on('click', '.nav-link', function(e){
                e.preventDefault();

                $(this).parent().siblings().removeClass('show');
                $(this).parent().toggleClass('show');

                asideBody.update()
            });

            $('body').on('mouseenter', '.minimize .aside-body', function(e){
                console.log('e');
                $(this).parent().addClass('maximize');
            });

            $('body').on('mouseleave', '.minimize .aside-body', function(e){
                $(this).parent().removeClass('maximize');

                asideBody.update()
            });

            $('body').on('click', '.aside-backdrop', function(e){
                $('body').removeClass('show-aside');
            })
        });
    }, []);

    return (
        <aside className="aside aside-fixed">
            <div className="aside-header">
                <a href="#" className="aside-logo">Con<span>sole</span></a>
                <a href="#" className="aside-menu-link">
                    <i data-feather="menu"></i>
                    <i data-feather="x"></i>
                </a>
            </div>
            <div className="aside-body">
                <div className="aside-loggedin">
                    <div className="aside-loggedin-user">
                        <a href="#loggedinMenu" className="d-flex align-items-center justify-content-between mg-b-2"
                           data-toggle="collapse">
                            <h6 className="tx-semibold mg-b-0">{auth.name}</h6>
                            <i data-feather="chevron-down"></i>
                        </a>
                        <p className="tx-color-03 tx-12 mg-b-0">Administrator</p>
                    </div>
                    <div className="collapse" id="loggedinMenu">
                        <ul className="nav nav-aside mg-b-0">
                            {/*<li className="nav-item"><a href="" className="nav-link"><i data-feather="settings"></i>*/}
                            {/*    <span>Account Settings</span></a></li>*/}
                            <li className="nav-item"><Link to="#" onClick={onLogoutClick.bind()} className="nav-link"><i data-feather="log-out"></i>
                                <span>Sign Out</span></Link></li>
                        </ul>
                    </div>
                </div>

                <ul className="nav nav-aside">
                    <li className="nav-label">Users</li>
                    {auth.access_privilege.user ? <li className={statistics}><Link to="/dashboard" onClick={handleActiveLink.bind(null, "dashboard", null)} className="nav-link"><i data-feather="users"></i> <span>Statistics</span></Link></li>: false}
                    {auth.access_privilege.transaction ? <li className={transaction}><Link to="/transaction" onClick={handleActiveLink.bind(null, "transaction", null)} className="nav-link"><i data-feather="bar-chart-2"></i> <span>Transaction</span></Link></li> : false}
                    {/*<li className="nav-item with-sub">*/}
                    {/*    <a href="#" className="nav-link"><i data-feather="codesandbox"></i> <span>App</span></a>*/}
                    {/*    <ul>*/}
                    {/*        <li><Link to="/publisher">Telecom</Link></li>*/}
                    {/*        <li><Link to="/publisher">Electricity</Link></li>*/}
                    {/*        <li><Link to="/publisher">Foods</Link></li>*/}
                    {/*        <li><Link to="/publisher">Groceries</Link></li>*/}
                    {/*        <li><Link to="/publisher">Cash</Link></li>*/}
                    {/*    </ul>*/}
                    {/*</li>*/}
                    <li className="nav-label mg-t-25">Advertiser</li>
                    <li className={campaign}><Link to="/campaign" onClick={handleActiveLink.bind(null, "campaign", null)} className="nav-link"><i data-feather="file"></i> <span>Campaign</span></Link></li>
                    <li className={clients}><Link to="/advertiser" onClick={handleActiveLink.bind(null, "clients", null)} className="nav-link"><i data-feather="user"></i><span>Clients</span></Link></li>
                    <li className={payment}><Link to="/payment" onClick={handleActiveLink.bind(null, "payment", null)} className="nav-link"><i data-feather="credit-card"></i> <span>Payment</span></Link></li>
                    <li className="nav-label mg-t-25">Applications</li>
                    {auth.access_privilege.mobile ? <li className={settings}>
                        <a href="#" className="nav-link"><i data-feather="smartphone"></i> <span>Mobile App</span></a>
                        <ul>
                            <li className={ad_publish}><Link to="/ad-publish" onClick={handleActiveLink.bind(null, "settings", "ad_publish")}>Ad Publish</Link></li>
                            <li><a href="#">Settings</a></li>
                        </ul>
                    </li>: false}
                    {auth.access_privilege.access_control ? <li className={push_notification}><Link to="/push-notification" onClick={handleActiveLink.bind(null, "push_notification", null)} className="nav-link"><i data-feather="bell"></i> <span>Push Notification</span></Link></li>: false}
                    {auth.access_privilege.access_control ? <li className={access_control}><Link to="/access-control" onClick={handleActiveLink.bind(null, "access_control", null)} className="nav-link"><i data-feather="users"></i> <span>Access Control</span></Link></li>: false}
                    {auth.access_privilege.access_control ? <li className={country}><Link to="/country" onClick={handleActiveLink.bind(null, "country", null)} className="nav-link"><i data-feather="globe"></i> <span>Country</span></Link></li>: false}
                </ul>
            </div>
        </aside>
    );
};

export default Header;
