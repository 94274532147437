import isEmpty from "../validation/is-empty";
import user from "../data/user.json";

import {createSlice} from '@reduxjs/toolkit';

const slice = createSlice({
    name: "auth",
    initialState: {
        user: null,
        isAuthenticated: false
    },
    reducers: {
        //actions => action handlers
        SET_CURRENT_USER: (state, action) => {
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload.isAuthenticated),
                user: action.payload.user
            };
        },
        UNAUTH_USER: () => {
            return {
                isAuthenticated: false,
                user: null
            };
        }
    }
});

export const {SET_CURRENT_USER, UNAUTH_USER} = slice.actions;
export default slice.reducer;
