import axios from 'axios';
import { GET_TOTAL_CAMPAIGN_STATISTICS_URL, CAMPAIGN_ACTION_URL, GET_CAMPAIGN_AD_URL, GET_CAMPAIGN_AD_DATA_URL, DELETE_CAMPAIGN_AD_URL} from '../../api';
import cogoToast from "cogo-toast";

export const campaignStatistic = () => {
    return function(dispatch) {
        return axios.get(GET_TOTAL_CAMPAIGN_STATISTICS_URL).then((response) => {
            dispatch({
                type: 'CAMPAIGN_STATISTICS',
                payload: response.data
            })
        }).catch((err) => {
            const option = {
                hideAfter: 5
            };

            cogoToast.error(err.response.data, option);
        });
    }
};

export const campaignAction = (id, type) => {
    return axios.post(CAMPAIGN_ACTION_URL, {id, type}).then((response) => {
        return {
            data: response.data.success,
            error: false
        }
    }).catch((err) => {
        return {
            data: err.response.data.error,
            error: true
        }
    });
};

export const campaignStore = (data) => {
    return function(dispatch) {
        dispatch({
            type: 'CAMPAIGN_STORE',
            payload: data
        })
    }
};

export const getCampaignAd = (id) => {
    return function(dispatch) {
        dispatch(setCampaignAdLoading());
        return axios.post(GET_CAMPAIGN_AD_URL, {id}).then((response) => {
            dispatch({
                type: 'GET_CAMPAIGN_AD',
                payload: response.data
            })
        }).catch((err) => {
            dispatch({
                type: 'GET_NO_CAMPAIGN_AD',
                payload: {}
            })
        });
    }
};

export const getCampaignAdData = (id) => {
    return axios.post(GET_CAMPAIGN_AD_DATA_URL, {id}).then((response) => {
        return {
            data: response.data,
            error: false
        }
    }).catch(() => {
        const options = {
            position: "top-right",
            hideAfter: 5
        };

        cogoToast.error("Error connecting to server.", options);
        return {
            data: null,
            error: true
        }
    });
};

export const deleteCampaignAd = (id, ad_id) => {
    return axios.post(DELETE_CAMPAIGN_AD_URL, {id, ad_id}).then((response) => {
        return {
            data: response.data,
            error: false
        }

    }).catch(() => {
        const option = {
            position: "top-right",
            hideAfter: 5
        };

        cogoToast.error("Error connecting to server.", option);
        return {
            data: null,
            error: true
        }
    });
};

// Campaign Loading
export const setCampaignAdLoading = () => {
    return {
        type: 'CAMPAIGN_AD_LOADING'
    }
};
