import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {getAdReport} from '../../../../redux/actions/adReportActions';
import {Eye, Mail, File, MoreVertical, GitPullRequest, AlignLeft, BarChart2} from 'react-feather';
import NumberFormat from "react-number-format";
import ClickType from "./report/ClickType";
import ClickAction from "./report/ClickAction";
import Geography from "./report/Geography";
import FlotChart from "./report/FlotChart";
import $ from 'jquery';
import 'components-jqueryui';
import 'select2';
import moment from "moment";
import {decodeQueryParameter, encodeQueryParameter} from "../../../../utils/url";
import formatNumber from "../../../../utils/formatNumber";
import {capitalize} from "../../../../utils/capitalize";
import {BarChart, ColumnChart, LineChart} from "react-chartkick";
import cogoToast from "cogo-toast";
import {useDispatch, useSelector} from "react-redux";
import Search from "../../Search";

const AdReport = (props) => {

    const ad_data = decodeQueryParameter(props.match.params.ad.replace(/'/g, ""));

    const [ad_report, _ad_report] = useState([]);
    const [selected_start, _selected_start] = useState(ad_data.start_date);
    const [selected_end, _selected_end] = useState(ad_data.end_date);
    const [_total_cost, __total_cost] = useState(0);
    const [first_time, _first_time] = useState(0);
    const [total_cpc, _total_cpc] = useState(0);
    const [total_cpa, _total_cpa] = useState(0);
    const [loading, _loading] = useState(true);

    const dispatch = useDispatch();
    const chart_type = "linechart";

    $(function () {
        $('#start_from').datepicker({
            showOtherMonths: true,
            selectOtherMonths: true,
            defaultDate: '+1w',
            numberOfMonths: 1,
            minDate: ad_data.start_date.slice(0,10),
            maxDate: new Date(ad_report.current_date),
            dateFormat: 'yy/mm/dd',
            onSelect: function(value) {
                console.log(value);
                let start_date = value+ " 00:00:00";
                let end_date = $("#end_from").val()+" 23:59:59";

                let option = {
                    hideAfter: 5
                };

                if(start_date > ad_data.end_date) {
                    return cogoToast.error(`Sorry, select the date between ${ad_data.start_date.slice(0,10)} to ${ad_data.end_date.slice(0,10)}.`, option);
                }

                if(start_date > end_date) {
                    return cogoToast.error("Enter date range correctly in order.", option);
                }

                getAdReport(ad_data, start_date, end_date).then((result) => {
                    if(result.error !== true) {
                        _selected_start(start_date);
                        _selected_end(end_date);
                        _ad_report(result.data);
                        _loading(false)
                    }
                });
            }
        }).datepicker('setDate', selected_start.slice(0,10));

        $('#end_from').datepicker({
            showOtherMonths: true,
            selectOtherMonths: true,
            numberOfMonths: 1,
            minDate: ad_data.start_date.slice(0,10),
            maxDate: new Date(ad_report.current_date),
            dateFormat: 'yy/mm/dd',
            onSelect: function(value) {
                let start_date = $("#start_from").val()+" 00:00:00";
                let end_date = value + " 23:59:59";

                let option = {
                    hideAfter: 5
                };

                if(start_date > ad_data.end_date) {
                    return cogoToast.error(`Sorry, select the date between ${ad_data.start_date.slice(0,10)} to ${ad_data.end_date.slice(0,10)}.`, option);
                }

                if(start_date > end_date) {
                    return cogoToast.error("Enter date range correctly in order.", option);
                }

                getAdReport(ad_data, start_date, end_date).then((result) => {
                    if(result.error !== true) {
                        _selected_start(start_date);
                        _selected_end(end_date);
                        _ad_report(result.data);
                        _loading(false)
                    }
                });
            }
        }).datepicker('setDate', selected_end.slice(0,10));

        $('#start').datepicker({
            showOtherMonths: true,
            selectOtherMonths: true,
            minDate: 2,
            defaultDate: '+1w',
            numberOfMonths: 1,
            dateFormat: 'yy/mm/dd'
        });
    });

    useEffect(() => {
        getAdReport(ad_data, ad_data.start_date, ad_data.end_date).then((result) => {
            if(result.error !== true) {
                _ad_report(result.data);
                _loading(false)
            }
        });

    }, []);

    const balance = () => {
        return parseInt(ad_data.budget) - total_cost(ad_report)
    };

    const total_cost = (raw) => {
        const cpm_array = raw.cost_model.find((result) => {
            return result.cpm;
        });

        const cpc_array = raw.cost_model.find((result) => {
            return result.cpc;
        });

        const cpa_array = raw.cost_model.find((result) => {
            return result.cpa;
        });

        let total_cpm = 0;
        let total_cpc = 0;
        let total_cpa = 0;

        if(cpm_array !== undefined) {
            if(ad_data.custom_cost.type !== "premium") {
                total_cpm = cpm_array.cpm.reduce((acc, count) => acc + count, 0) * 0.20;
            } else {
                total_cpm = cpm_array.cpm.reduce((acc, count) => acc + count, 0) * 0.30;
            }
        }

        if(cpc_array !== undefined) {
            if(ad_data.custom_cost.type !== "premium") {
                total_cpc = cpc_array.cpc.reduce((acc, count) => acc + count, 0) * 0.30;
            } else {
                total_cpc = cpc_array.cpc.reduce((acc, count) => acc + count, 0) * 0.45;
            }
        }

        if(cpa_array !== undefined) {
            if(ad_data.custom_cost.type !== "premium") {
                total_cpa = cpa_array.cpa.reduce((acc, count) => acc + count, 0) * 0.50;
            } else {
                total_cpa = cpa_array.cpa.reduce((acc, count) => acc + count, 0) * 0.75;
            }
        }

        if(first_time === 0) {
            _first_time(1);
            return __total_cost(total_cpm + total_cpc + total_cpa);
        } else {
            return total_cpm + total_cpc + total_cpa;
        }
    };

    const handleModalAdDetail = () => {
        $("#modalAdDetail").modal("show");
    };

    let adContent;

    if(ad_report === null || loading) {
        adContent =
            <div className="content content-fixed content-auth-alt">
                <div className="container d-flex justify-content-center ht-100p">
                    <div className="mx-wd-300 wd-sm-450 ht-100p d-flex flex-column align-items-center justify-content-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        <p className="mg-t-10">Loading Campaign</p>
                    </div>
                </div>
            </div>
    } else {

        let renderWebsiteLink = () => {
            if(ad_data.uri !== "not_set") {
                return(
                    <span className="tx-13 tx-color-02"><span className="tx-semibold">Website Link:</span>{ad_data.uri}</span>
                );
            }
        };

        const hour_time = (hour) => {
            if(hour <= 9) {
                return "0" + hour + ":00 Hrs";
            }

            return hour + ":00 Hrs";
        };

        let renderAge = (age) => {
            if(age === true) {
                return <i className="fa fa-check-circle tx-success"></i>
            }

            return <i className="fa fa-ban tx-danger"></i>
        };

        var chart_data = ad_report.chart_data;

        const handleFilter = () => {
            let start_date = $("#start_from").val()+" 00:00:00";
            let end_date = $("#end_from").val()+" 23:59:59";

            if(start_date > ad_data.end_date) {
                return cogoToast.error(`Sorry, select the date between ${ad_data.start_date.slice(0,10)} to ${ad_data.end_date.slice(0,10)}.`, option);
            }

            if(start_date > end_date) {
                return cogoToast.error("Enter date range correctly in order.", option);
            }

            getAdReport(ad_data, start_date, end_date).then((result) => {
                if(result.error !== true) {
                    _selected_start(start_date);
                    _selected_end(end_date);
                    _ad_report(result.data);
                    _loading(false)
                }
            });
        };

        const handleChart = (type) => {
            dispatch({
                type: 'CHART_TYPE',
                payload: type
            });
        };

        const option = {
            hideAfter: 5
        };

        let renderChart = () => {
            if(chart_type === "barchart") {
                return (
                    <BarChart
                        data={chart_data}
                        download={true} legend="bottom"
                        height="400px"
                        colors={["#3843da", "#da3849", "#da6a38"]}
                        messages={{empty: "No data Available"}}
                        dataset={{borderWidth: 1}}
                    />
                );
            }

            if(chart_type === "columnchart") {
                return (
                    <ColumnChart
                        data={chart_data}
                        download={true} legend="bottom"
                        height="400px"
                        colors={["#3843da", "#da3849", "#da6a38"]}
                        messages={{empty: "No data Available"}}
                        dataset={{borderWidth: 1}}
                    />
                );
            }

            return (
                <LineChart
                    data={chart_data}
                    download={true} legend="bottom"
                    height="400px"
                    curve={false}
                    colors={["#3843da", "#da3849", "#da6a38"]}
                    messages={{empty: "No data Available"}}
                    dataset={{borderWidth: 1}}
                />
            );
        };

        const clickType = () => {
            const total_cpm = () => {
                let cpm_array = ad_report.cost_model.find((result) => {
                    return result.cpm;
                });

                return formatNumber(cpm_array.cpm[0]+cpm_array.cpm[1]+cpm_array.cpm[2]+cpm_array.cpm[3]+cpm_array.cpm[4]+cpm_array.cpm[5]+cpm_array.cpm[6]+cpm_array.cpm[7]);
            };

            if(ad_data.cost_model === "cpa") {
                return (
                    <div className="row row-sm text-center">
                        <div className="col-6 col-sm-4 col-md">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 mg-b-10">Total Clicks for Selected Period</h6>
                            <h5 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1"><NumberFormat value={ad_report.m_group_a+ad_report.m_group_b+ad_report.m_group_c+ad_report.m_group_d+ad_report.f_group_a+ad_report.f_group_b+ad_report.f_group_c+ad_report.f_group_d} thousandSeparator={true} displayType={'text'} decimalScale={0}/></h5>
                        </div>
                        <div className="col-6 col-sm-4 col-md">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary mg-b-10">CPM</h6>
                            <h5 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{total_cpm()}</h5>
                        </div>
                        <div className="col-6 col-sm-4 col-md mg-t-20 mg-sm-t-0">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-danger mg-b-10">CPC</h6>
                            <h5 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{total_cpc}</h5>
                        </div>
                        <div className="col-6 col-sm-4 col-md mg-t-20 mg-sm-t-0">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-indigo mg-b-10">CPA</h6>
                            <h5 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{total_cpc}</h5>
                        </div>
                    </div>
                )
            }

            if(ad_data.cost_model === "cpc") {
                return (
                    <div className="row row-sm text-center">
                        <div className="col-6 col-sm-4 col-md">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 mg-b-10">Total Clicks for Selected Period</h6>
                            <h5 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1"><NumberFormat value={ad_report.m_group_a+ad_report.m_group_b+ad_report.m_group_c+ad_report.m_group_d+ad_report.f_group_a+ad_report.f_group_b+ad_report.f_group_c+ad_report.f_group_d} thousandSeparator={true} displayType={'text'} decimalScale={0}/></h5>
                        </div>
                        <div className="col-6 col-sm-4 col-md">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary mg-b-10">CPM</h6>
                            <h5 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{total_cpm()}</h5>
                        </div>
                        <div className="col-6 col-sm-4 col-md mg-t-20 mg-sm-t-0">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-danger mg-b-10">CPC</h6>
                            <h5 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{total_cpc}</h5>
                        </div>
                    </div>
                )
            }

            return (
                <div className="row row-sm text-center">
                    <div className="col-6 col-sm-4 col-md">
                        <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 mg-b-10">Total Clicks for Selected Period</h6>
                        <h5 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1"><NumberFormat value={ad_report.m_group_a+ad_report.m_group_b+ad_report.m_group_c+ad_report.m_group_d+ad_report.f_group_a+ad_report.f_group_b+ad_report.f_group_c+ad_report.f_group_d} thousandSeparator={true} displayType={'text'} decimalScale={0}/></h5>
                    </div>
                    <div className="col-6 col-sm-4 col-md">
                        <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary mg-b-10">CPM</h6>
                        <h5 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{total_cpm()}</h5>
                    </div>
                </div>
            )
        };

        adContent =
            <div className="content-body ht-100v pd-b-0">
                <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
                    <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-20 mg-xl-b-20">
                        <div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                    <li className="breadcrumb-item">Dashboard</li>
                                    <li className="breadcrumb-item"><Link to="#" onClick={() => props.history.goBack()}>Campaign</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Ads</li>
                                </ol>
                            </nav>
                            <h4 className="mg-b-0 tx-spacing--1">{ad_data.name}</h4>
                        </div>
                        <div className="d-none d-md-block">
                            <button onClick={handleModalAdDetail.bind()} className="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5 mg-r-5"><Eye className="wd-10 mg-r-5"/> View Ad Details</button>
                            <button disabled className="btn btn-sm pd-x-15 btn-white btn-uppercase"><Mail className="wd-10 mg-r-5"/> Email</button>
                            <a href={`#/ad-generate-report/${encodeQueryParameter(ad_data)}/${encodeQueryParameter(ad_report)}/${encodeQueryParameter(selected_start)}/${encodeQueryParameter(selected_end)}`} className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><File className="wd-10 mg-r-5"/> Generate Report</a>
                        </div>
                    </div>

                    <div className="row row-xs">
                        <div className="col-lg-4 col-md-6 mg-t-10">
                            <div className="card">
                                <div className="card-body pd-y-20 pd-x-25">
                                    <div className="row row-sm">
                                        <div className="col-12">
                                            <h3 className="tx-normal tx-rubik tx-spacing--1 mg-b-5"><NumberFormat value={ad_report.daily_clicks} thousandSeparator={true} displayType={'text'} decimalScale={0}/></h3>
                                            <h6 className="tx-12 tx-semibold tx-uppercase tx-spacing-1 tx-primary mg-b-5">Daily Clicks</h6>
                                            <p className="tx-11 tx-color-03 mg-b-0">Date: {moment(ad_report.current_date).format("Do MMM, YYYY")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 mg-t-10">
                            <div className="card">
                                <div className="card-body pd-y-20 pd-x-25">
                                    <div className="row row-sm">
                                        <div className="col-12">
                                            <h3 className="tx-normal tx-rubik tx-spacing--1 mg-b-5"><NumberFormat value={ad_report.total_clicks} thousandSeparator={true} displayType={'text'} decimalScale={0}/></h3>
                                            <h6 className="tx-12 tx-semibold tx-uppercase tx-spacing-1 tx-primary mg-b-5">Total Clicks to Date</h6>
                                            <p className="tx-11 tx-color-03 mg-b-0">Ad Total Clicks: {ad_data.total_clicks}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 mg-t-10">
                            <div className="card">
                                <div className="card-body pd-y-20 pd-x-25">
                                    <div className="row row-sm">
                                        <div className="col-12">
                                            <h3 className="tx-normal tx-rubik tx-spacing--1 mg-b-5"><NumberFormat value={_total_cost || total_cost(ad_report)} thousandSeparator={true} displayType={'text'} decimalScale={2} prefix={'R'}/></h3>
                                            <h6 className="tx-12 tx-semibold tx-uppercase tx-spacing-1 tx-primary mg-b-5">Total Cost To Date</h6>
                                            <p className="tx-11 tx-color-03 mg-b-0">Ad Budget: R{ad_data.budget}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card card-crypto mg-t-15">
                        <div className="card-header pd-y-8 d-sm-flex align-items-center justify-content-between">
                            <div className="dropdown-file">
                                <a href="" className="dropdown-link" data-toggle="dropdown"><MoreVertical/></a>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <Link to="#" onClick={handleChart.bind(null, "linechart")} className="dropdown-item details"><GitPullRequest/> Line Chart</Link>
                                    <Link to="#" onClick={handleChart.bind(null, "barchart")} className="dropdown-item details"><AlignLeft/> Bar Chart</Link>
                                    <Link to="#" onClick={handleChart.bind(null, "columnchart")} className="dropdown-item details"><BarChart2/> Column Chart</Link>
                                </div>
                            </div>
                            <div className="tx-12 tx-color-03 align-items-center d-sm-flex">
                                <input type="text" id="start_from" className="tx-spacing-1 tx-rubik" placeholder="Start Date" style={{border: 0, width: 80}}/>
                                <span className="mg-r-10 mg-l-10">to</span>
                                <input type="text" id="end_from" className="tx-spacing-1 tx-rubik" placeholder="End Date"  style={{border: 0, width: 80}}/>
                            </div>
                        </div>
                        <div className="card-body pd-10 pd-sm-20">
                            {renderChart()}
                        </div>
                        <div className="card-footer pd-20">
                            {clickType()}
                        </div>
                    </div>

                    {/*<FlotChart ad_data={ad_data} selected_date={ad_report.selected_date} current_date={ad_report.current_date} chart_data={ad_report.chart_data}/>*/}

                    <ClickType ad_report={ad_report.cost_model} cost_model={ad_data.cost_model}/>

                    <ClickAction ad_report={ad_report}/>

                    <Geography ad_report={ad_report.region} ad_data={ad_data} total_clicks={ad_report.total_clicks}/>

                    <div className="modal fade" id="modalAdDetail" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-body pd-x-25 pd-sm-x-30 pd-t-40 pd-sm-t-20 pd-b-15 pd-sm-b-20">
                                    <a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </a>
                                    <div className="nav-wrapper mg-b-20 tx-13">
                                        <div>
                                            <nav className="nav nav-line tx-medium">
                                                <a href="#ad_detail" className="nav-link active" data-toggle="tab">Details</a>
                                                <a href="#preview" className="nav-link" data-toggle="tab">Preview</a>
                                            </nav>
                                        </div>
                                    </div>

                                    <div className="tab-content">
                                        <div id="ad_detail" className="tab-pane fade active show">

                                            <h4 className="tx-normal tx-rubik mg-b-10 mg-r-5"> {ad_data.name || "No Data"}</h4>

                                            <div className="progress bg-transparent op-7 ht-10 mg-b-20">
                                                <div className="progress-bar bg-primary wd-50p" role="progressbar" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"/>
                                                <div className="progress-bar bg-success wd-25p bd-l bd-white" role="progressbar" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"/>
                                                <div className="progress-bar bg-warning wd-5p bd-l bd-white" aria-valuemax="100"/>
                                                <div className="progress-bar bg-pink wd-5p bd-l bd-white" role="progressbar" aria-valuenow="5" aria-valuemin="0" aria-valuemax="100"/>
                                                <div className="progress-bar bg-teal wd-10p bd-l bd-white" role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"/>
                                                <div className="progress-bar bg-purple wd-5p bd-l bd-white" role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"/>
                                            </div>

                                            <div className="list-group list-group-flush tx-13 mg-b-0">
                                                <div className="list-group list-group-flush tx-13 mg-b-0">
                                                    <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                        <strong className="tx-12 tx-rubik">Budget</strong>
                                                        <div className="mg-l-auto tx-rubik tx-color-02">R{formatNumber(ad_data.budget)}</div>
                                                    </div>
                                                    <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                        <strong className="tx-12 tx-rubik">Duration</strong>
                                                        <div className="mg-l-auto tx-rubik tx-color-02">{ad_data.duration} Days</div>
                                                    </div>
                                                    <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                        <strong className="tx-12 tx-rubik">Display Time</strong>
                                                        <div className="mg-l-auto tx-rubik tx-color-02">{capitalize(ad_data.custom_cost.type)}</div>
                                                    </div>
                                                    <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                        <strong className="tx-12 tx-rubik">Start Date</strong>
                                                        <div className="mg-l-auto tx-rubik tx-color-02">{moment(ad_data.start_date).format("Do MMM, YYYY")}</div>
                                                    </div>
                                                    <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                        <strong className="tx-12 tx-rubik">End Date</strong>
                                                        <div className="mg-l-auto tx-rubik tx-color-02">{moment(ad_data.end_date).format("Do MMM, YYYY")}</div>
                                                    </div>
                                                    <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                        <strong className="tx-12 tx-rubik">Cost Model</strong>
                                                        <div className="mg-l-auto tx-rubik tx-color-02">{ad_data.cost_model.toUpperCase()}</div>
                                                    </div>
                                                    <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                        <strong className="tx-12 tx-rubik">Click Per Day</strong>
                                                        <div className="mg-l-auto tx-rubik tx-color-02">{formatNumber(ad_data.click_per_day)}</div>
                                                    </div>
                                                    <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                        <strong className="tx-12 tx-rubik">Total Clicks</strong>
                                                        <div className="mg-l-auto tx-rubik tx-color-02">{formatNumber(ad_data.total_clicks)}</div>
                                                    </div>
                                                    <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                        <strong className="tx-12 tx-rubik">Gender</strong>
                                                        <div className="mg-l-auto tx-rubik tx-color-02">{capitalize(ad_data.gender)}</div>
                                                    </div>
                                                    <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                        <strong className="tx-12 tx-rubik">Start Time</strong>
                                                        <div className="mg-l-auto tx-rubik tx-color-02">{hour_time(ad_data.custom_cost.start_hour)}</div>
                                                    </div>
                                                    <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                        <strong className="tx-12 tx-rubik">End Time</strong>
                                                        <div className="mg-l-auto tx-rubik tx-color-02">{hour_time(ad_data.custom_cost.end_hour)}</div>
                                                    </div>
                                                    <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                        <strong className="tx-12 tx-rubik">Target Area</strong>
                                                        <div className="mg-l-auto tx-rubik tx-color-02">{capitalize(ad_data.region.type)}</div>
                                                    </div>
                                                    <div className="list-group-item pd-y-5 pd-x-0">
                                                        <strong className="tx-12 tx-rubik">Category</strong>
                                                        <ol>
                                                            {ad_data.category.map((result) => {
                                                                return(
                                                                    <li><strong className="tx-12 tx-rubik">{result}</strong></li>
                                                                );
                                                            })}
                                                        </ol>
                                                    </div>
                                                    <div className="list-group-item pd-y-5 pd-x-0">
                                                        <strong className="tx-12 tx-rubik">Age Group</strong>
                                                        <ul>
                                                            <li><strong className="tx-12 tx-rubik">18 - 24: {renderAge(ad_data.age.group_a)}</strong></li>
                                                            <li><strong className="tx-12 tx-rubik">25 - 34: {renderAge(ad_data.age.group_b)}</strong></li>
                                                            <li><strong className="tx-12 tx-rubik">35 - 44: {renderAge(ad_data.age.group_c)}</strong></li>
                                                            <li><strong className="tx-12 tx-rubik">45 and Above: {renderAge(ad_data.age.group_d)}</strong></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="preview" className="tab-pane fade">
                                            <div className="pd-b-15 d-flex align-items-baseline">
                                                <img src={ad_data.img_src} className="rounded mg-r-10 wd-xl-100p" alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    }

    return (
        <div>
            {adContent}
        </div>
    )
};

export default AdReport;
