import React, { useEffect } from 'react';
import {Link} from 'react-router-dom';
import {GET_SUBURB_STATISTICS} from '../../../api';
import $ from "jquery";
import formatNumber from "../../../utils/formatNumber";
import Header from "../../layout/Header";
import Search from "../Search";

const RegionSuburb = (props) => {
    const token = localStorage.getItem('jwtToken');
    const name = props.match.params.name;

    useEffect(() => {

        $('#my_modal').on('show.bs.modal', function(e) {
            let raw = $(e.relatedTarget).data('data-id').split(",").map(String);

            $("#mag1").text(formatNumber(raw[1]));
            $("#mag2").text(formatNumber(raw[2]));
            $("#mag3").text(formatNumber(raw[3]));
            $("#mag4").text(formatNumber(raw[4]));

            $("#fag1").text(formatNumber(raw[5]));
            $("#fag2").text(formatNumber(raw[6]));
            $("#fag3").text(formatNumber(raw[7]));
            $("#fag4").text(formatNumber(raw[8]));

            $("#title").text(raw[0]);
        });

        $('#city_').DataTable({
            responsive: true,
            "searching": true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Suburb Report</p>'
            },
            "lengthMenu": [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(GET_SUBURB_STATISTICS, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        pageSize: data.start,
                        format: 'json',
                        city: name,
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success : function(res) {
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: res.data
                        });
                    }
                });
            },
            "columns": [
                {"data": "name"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return formatNumber(parseInt(data[1]) + parseInt(data[2]) + parseInt(data[3]) + parseInt(data[4]) + parseInt(data[5]) + parseInt(data[6]) + parseInt(data[7]) + parseInt(data[8]));
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return formatNumber(parseInt(data[1]) + parseInt(data[2]) + parseInt(data[3]) + parseInt(data[4]));
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return formatNumber(parseInt(data[5]) + parseInt(data[6]) + parseInt(data[7]) + parseInt(data[8]));
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return `<nav class="nav nav-icon-only"><a href="#my_modal" data-toggle="modal" data-data-id='${data}' class="nav-link outline-none"><i class="fa fa-restroom"></i> Age</a></nav>`
                    },
                    "targets": 4
                }
            ]
        });
    }, []);

    return(
        <div className="content-body ht-100v pd-b-0">
            <div className="container pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                <li className="breadcrumb-item"><Link to="#" onClick={() => props.history.goBack()}>State</Link></li>
                                <li className="breadcrumb-item"><Link to="#" onClick={() => props.history.goBack()}>City</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Suburb</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">{name}</h4>
                    </div>
                </div>

                <div data-label="Total Active Users" className="df-example demo-table mg-t-20">
                    <table id="city_" className="table">
                        <thead>
                        <tr>
                            <th className="wd-20p">Suburb/Township</th>
                            <th className="wd-15p">Total</th>
                            <th className="wd-15p">Male</th>
                            <th className="wd-15p">Female</th>
                            <td className="wd-15p">Actions</td>
                        </tr>
                        </thead>
                    </table>
                    <div className="modal fade" id="my_modal" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="media align-items-center">
                                        <div className="media-body">
                                            <a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </a>
                                            <p className="tx-13 tx-color-02 mg-b-0 tx-semibold" id="title">Error</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <table id="geography" className="table tx-13 mg-b-0">
                                        <thead>
                                        <tr>
                                            <th>Age Group</th>
                                            <th>Male</th>
                                            <th className="text-right">Female</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>18 - 24</td>
                                            <td id="mag1">0</td>
                                            <td id="fag1" className="text-right">0</td>
                                        </tr>
                                        <tr>
                                            <td>25 - 34</td>
                                            <td id="mag2">0</td>
                                            <td id="fag2" className="text-right">0</td>
                                        </tr>
                                        <tr>
                                            <td>35 - 44</td>
                                            <td id="mag3">0</td>
                                            <td id="fag3" className="text-right">0</td>
                                        </tr>
                                        <tr>
                                            <td>45 and above</td>
                                            <td id="mag4">0</td>
                                            <td id="fag4" className="text-right">0</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegionSuburb;
