import axios from "axios";
import {USER_SEARCH_URL} from "../api";
import {USER_SEARCH} from "../stores/user";
import cogoToast from "cogo-toast";

export const searchUser = (query) => {
    return function(dispatch) {
        return axios.post(USER_SEARCH_URL, {query}).then((response) => {
            dispatch(USER_SEARCH({user_search: response.data}));
            return {
                data: response.data,
                error: false
            }
        }).catch((err) => {
            const option = {
                position: "top-right",
                hideAfter: 3
            };

            cogoToast.error(err.response.data.error, option);
            return {
                data: null,
                error: true
            }
        });
    }
};
