import React, {useEffect, useState} from 'react';
import Header from '../../layout/Header';
import {useDispatch} from 'react-redux';
import {GET_ADVERTISER_REGISTRAR_URL} from '../../../api';
import {campaignStatistic, getAdvertiserStatistic} from '../../../redux/actions';
import formatNumber from "../../../utils/formatNumber";
import 'datatables.net';
import 'datatables.net-responsive';
import moment from 'moment';
import $ from "jquery";
import Search from "../Search";
import {Link} from "react-router-dom";
// import UserModal from "../shared/UserModal";

const Advertiser = (props) => {

    document.title = "Advertiser Registration";

    const token = localStorage.getItem('jwtToken');
    const dispatch = useDispatch();

    useEffect(() => {
        // dispatch(getAdvertiserStatistic());

        $('#my_modal').on('show.bs.modal', function(e) {
            let raw = $(e.relatedTarget).data('data-id');

            $("#email").text(raw.email);
            $("#business_name").text(raw.business_name || "No Data");
            $("#person_name").text(raw.person_name || "No Data");
            $("#contact_number").text(raw.contact_number || "No Data");
            $("#address_1").text(raw.address || "No Data");
            $("#address_2").text(raw.extra_address || "No Data");
            $("#city").text(raw.city || "No Data");
            $("#state").text(raw.state || "No Data");
            $("#postal_code").text(raw.postal_code || "No Data");
            $("#vat_number").text(raw.vat_number);
            $("#date").text(raw.date_created);

            $("#title").text(raw.email);
        });

        $('#advertiser').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Advertiser Report</p>'
            },
            "searching": true,
            "lengthMenu": [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(GET_ADVERTISER_REGISTRAR_URL, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        pageSize: data.start,
                        format: 'json',
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success : function(res) {
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: res.data
                        });
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data.business_name
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        if(data.person_name !== "") {
                            return data.person_name
                        } else {
                            return "No record"
                        }
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        try {
                            return data.country
                        } catch (e) {
                            return "No record"
                        }
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return moment(data.date_created).format("Do MMM, YYYY");
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        let dart = JSON.stringify(data);
                        return '<nav class="nav nav-icon-only"><a href="#my_modal" data-toggle="modal" data-data-id=\'' +dart+ '\' class="nav-link outline-none"><i class="ion-md-eye tx-18"></i> View</a></nav>'
                        // return '<nav class="nav nav-icon-only"><a href="#my_modal" data-toggle="modal" data-data-id=\'' +dart+ '\' class="nav-link outline-none"><i class="ion-md-eye tx-18"></i> View</a> <a href="#" class="nav-link outline-none"><i class="ion-ios-people tx-18"></i> Reset</a> <a href="#" class="nav-link outline-none"><i class="ion-ios-trash tx-18"></i> Delete</a></nav>'
                    },
                    "targets": 4
                }
            ]
        });
    }, []);

    const reloadTable = () => {
        $('#advertiser').DataTable().ajax.reload();
    };

    return (
        <div className="content-body ht-100v pd-b-0">
            <div className="container pd-x-0">
                <div
                    className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Clients</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Clients</h4>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Clients List</h6>
                                <div className="d-flex tx-18">
                                    <Link to="#" onClick={reloadTable.bind()} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></Link>
                                    {/*<a href="" className="link-03 lh-0 mg-l-10"><i className="icon ion-md-more"></i></a>*/}
                                </div>
                            </div>
                            <div className="card-body">
                                <table id="advertiser" className="table">
                                    <thead>
                                    <tr>
                                        <th className="wd-25p">Name</th>
                                        <th className="wd-20p">Person Name</th>
                                        <th className="wd-15p">Country</th>
                                        <th className="wd-15p">Date</th>
                                        <th className="wd-20p">Actions</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                {/*<UserModal/>*/}

                <div className="modal fade" id="my_modal" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body pd-x-25 pd-sm-x-30 pd-t-40 pd-sm-t-20 pd-b-15 pd-sm-b-20">
                                <a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </a>
                                <div className="nav-wrapper mg-b-20 tx-13">
                                    <div>
                                        <nav className="nav nav-line tx-medium">
                                            <a href="#advertiser" className="nav-link active" data-toggle="tab">Advertiser</a>
                                        </nav>
                                    </div>
                                </div>

                                <div className="tab-content">
                                    <div id="advertiser" className="tab-pane fade active show">
                                        <div className="progress bg-transparent op-7 ht-10 mg-b-20">
                                            <div className="progress-bar bg-primary wd-50p" role="progressbar" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"/>
                                            <div className="progress-bar bg-success wd-25p bd-l bd-white" role="progressbar" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"/>
                                            <div className="progress-bar bg-warning wd-5p bd-l bd-white" aria-valuemax="100"/>
                                            <div className="progress-bar bg-pink wd-5p bd-l bd-white" role="progressbar" aria-valuenow="5" aria-valuemin="0" aria-valuemax="100"/>
                                            <div className="progress-bar bg-teal wd-10p bd-l bd-white" role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"/>
                                            <div className="progress-bar bg-purple wd-5p bd-l bd-white" role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"/>
                                        </div>

                                        <div className="list-group list-group-flush tx-13 mg-b-0">
                                            <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                <strong className="tx-12 tx-rubik">Business Name</strong>
                                                <div id="business_name" className="mg-l-auto tx-rubik tx-color-02">Administrator</div>
                                            </div>
                                            <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                <strong className="tx-12 tx-rubik">Email</strong>
                                                <div id="email" className="mg-l-auto tx-rubik tx-color-02">admin@adzlok.co.za</div>
                                            </div>
                                            <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                <strong className="tx-12 tx-rubik">Person Name</strong>
                                                <div id="person_name" className="mg-l-auto tx-rubik tx-color-02">Administrator</div>
                                            </div>
                                            <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                <strong className="tx-12 tx-rubik">Contact Number</strong>
                                                <div id="contact_number" className="mg-l-auto tx-rubik tx-color-02">0600987655</div>
                                            </div>
                                            <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                <strong className="tx-12 tx-rubik">Address 1</strong>
                                                <div id="address_1" className="mg-l-auto tx-rubik tx-color-02">2 Western Service Road</div>
                                            </div>
                                            <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                <strong className="tx-12 tx-rubik">Address 2</strong>
                                                <div id="address_2" className="mg-l-auto tx-rubik tx-color-02">Blesser Streer</div>
                                            </div>
                                            <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                <strong className="tx-12 tx-rubik">City</strong>
                                                <div id="city" className="mg-l-auto tx-rubik tx-color-02">Sandton</div>
                                            </div>
                                            <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                <strong className="tx-12 tx-rubik">State</strong>
                                                <div id="state" className="mg-l-auto tx-rubik tx-color-02">Gauteng</div>
                                            </div>
                                            <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                <strong className="tx-12 tx-rubik">Postal Code</strong>
                                                <div id="postal_code" className="mg-l-auto tx-rubik tx-color-02">2050</div>
                                            </div>
                                            <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                <strong className="tx-12 tx-rubik">Vat Number</strong>
                                                <div id="vat_number" className="mg-l-auto tx-rubik tx-color-02">#9999</div>
                                            </div>
                                            <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                <strong className="tx-12 tx-rubik">Date</strong>
                                                <div id="date" className="mg-l-auto tx-rubik tx-color-02">2018-10-09</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Advertiser;
