import React, { Component } from 'react';
import formatNumber from "../../../../../utils/formatNumber";

class ClickAction extends Component {

    render() {
        const ad_report = this.props.ad_report;
        return (
            <div data-label="Clicks Action Area & Age Group" className="df-example demo-table mg-t-20">
                <div className="row row-xs mg-b-10">
                    <div className="col-lg-4 col-md-6 mg-t-10">
                        <div className="card">
                            <div className="card-body pd-y-20 pd-x-25">
                                <div className="row row-sm">
                                    <div className="col-12">
                                        <h3 className="tx-normal tx-rubik tx-spacing--1 mg-b-5">{formatNumber(this.props.ad_report.total_lockscreen)}</h3>
                                        <h6 className="tx-12 tx-semibold tx-uppercase tx-spacing-1 tx-danger mg-b-5">Lockscreen</h6>
                                        <p className="tx-11 tx-color-03 mg-b-0">No. of clicks to ad that consist of a
                                            single impression.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mg-t-10">
                        <div className="card">
                            <div className="card-body pd-y-20 pd-x-25">
                                <div className="row row-sm">
                                    <div className="col-12">
                                        <h3 className="tx-normal tx-rubik tx-spacing--1 mg-b-5">{formatNumber(this.props.ad_report.total_home)}</h3>
                                        <h6 className="tx-12 tx-semibold tx-uppercase tx-spacing-1 tx-danger mg-b-5">Home</h6>
                                        <p className="tx-11 tx-color-03 mg-b-0">No. of clicks to ad that consist of a
                                            single impression.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mg-t-10">
                        <div className="card">
                            <div className="card-body pd-y-20 pd-x-25">
                                <div className="row row-sm">
                                    <div className="col-12">
                                        <h3 className="tx-normal tx-rubik tx-spacing--1 mg-b-5">{formatNumber(this.props.ad_report.total_viewed)}</h3>
                                        <h6 className="tx-12 tx-semibold tx-uppercase tx-spacing-1 tx-danger mg-b-5">Viewed</h6>
                                        <p className="tx-11 tx-color-03 mg-b-0">No. of clicks to ad that consist of a
                                            single impression.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <table className="table table-bordered">
                    <thead>
                    <tr>
                        <th className="wd-20p">Age Group</th>
                        <th className="wd-15p">Male</th>
                        <th className="wd-10p">Female</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>18 - 24</td>
                        <td>{formatNumber(ad_report.m_group_a)}</td>
                        <td>{formatNumber(ad_report.f_group_a)}</td>
                    </tr>
                    <tr>
                        <td>25 - 34</td>
                        <td>{formatNumber(ad_report.m_group_b)}</td>
                        <td>{formatNumber(ad_report.f_group_b)}</td>
                    </tr>
                    <tr>
                        <td>35 - 44</td>
                        <td>{formatNumber(ad_report.m_group_c)}</td>
                        <td>{formatNumber(ad_report.f_group_c)}</td>
                    </tr>
                    <tr>
                        <td>45 and above</td>
                        <td>{formatNumber(ad_report.m_group_d)}</td>
                        <td>{formatNumber(ad_report.f_group_d)}</td>
                    </tr>
                    <tr>
                        <td className="tx-semibold">Total</td>
                        <td className="tx-semibold">{formatNumber(ad_report.m_group_a+ad_report.m_group_b+ad_report.m_group_c+ad_report.m_group_d)}</td>
                        <td className="tx-semibold">{formatNumber(ad_report.f_group_a+ad_report.f_group_b+ad_report.f_group_c+ad_report.f_group_d)}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default ClickAction;
