import React, {useEffect, useState} from 'react';
import {GET_COUNTRY_LIST_URL} from '../../../api';
import 'datatables.net';
import 'datatables.net-responsive';
import $ from "jquery";
import {Link} from "react-router-dom";
import cogoToast from "cogo-toast";
import {deleteCountry, addCountry, stateCountry} from "../../../redux/actions";
import countries from "../../../data/countries.json";
import {capitalize} from "../../../utils/capitalize";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";

const Country = () => {

    document.title = "Country";

    const id = useSelector((state) => state.auth.user.sub);
    const [stateReady, setStateReady] = useState(false);
    const [checked, setChecked] = useState(false);
    const [countrySelected, setCountrySelected] = useState("");

    const token = localStorage.getItem('jwtToken');

    useEffect(() => {
        var table = $('#country').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting country list</p>'
            },
            "searching": true,
            "lengthMenu": [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(GET_COUNTRY_LIST_URL, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        pageSize: data.start,
                        format: 'json',
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success : function(res) {
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: res.data
                        });
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data.name
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data.currency
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return `+${data.code}`
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return data.status ? '<span class="badge badge-success">Enabled</span>' : '<span class="badge badge-danger">Disabled</span>'
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return `<nav class="nav nav-icon-only">
                                    <a href="#" class="nav-link delete_country"><i class="fa fa-trash-alt"></i> Delete</a>
                                    ${data.status ? `<a href="#" class="nav-link state_country"><i class="fa fa-ban"></i> Disable</a>` : `<a href="#" class="nav-link state_country"><i class="far fa-check-circle"></i> Enable</a>`}
                                </nav>`
                    },
                    "targets": 4
                }
            ]
        });

        $('#country').on('click', '.delete_country', function() {
            let extract_td = table.row($(this).closest('tr')).data();

            const options = {
                position: "top-right",
                hideAfter: 5
            };

            let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

            deleteCountry(extract_td.raw._id).then((result) => {
                hide();
                const _options = {
                    position: "top-right"
                };

                if(result.error !== true) {
                    $('#country').DataTable().ajax.reload(null, false);
                    cogoToast.success(result.data, _options);
                }
            });

            return false;
        });

        $('#country').on('click', '.state_country', function() {
            let extract_td = table.row($(this).closest('tr')).data();

            let stateOpposite = extract_td.raw.status ? false : true;

            const options = {
                position: "top-right",
                hideAfter: 5
            };

            let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

            stateCountry(extract_td.raw._id, stateOpposite).then((result) => {
                hide();
                const _options = {
                    position: "top-right"
                };

                if(result.error !== true) {
                    $('#country').DataTable().ajax.reload(null, false);
                    cogoToast.success(result.data, _options);
                }
            });

            return false;
        });
    }, []);

    const handleSubmit = () => {
        if($('#country_selected').parsley().isValid() === false) {
            return false;
        }

        let raw = countries;

        const options = {
            position: "top-right",
            hideAfter: 0
        };

        let type = "standard";

        let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

        let data = raw.find(result => result.name == countrySelected);

        addCountry(data, checked).then((result) => {
            hide();
            const _options = {
                position: "top-right"
            };

            if(result.error !== true) {
                handleResetForm();
                $("#modalAddUser").modal("hide");
                $(".modal-backdrop").remove();
                $('#country').DataTable().ajax.reload();
                return cogoToast.success(result.data, _options);
            }
        });

    };

    const handleCountrySelected = (event) => {
        setCountrySelected(event.target.value);
    };

    const handle_check = () => {
        if($('#checked').is(':checked')){
            setChecked(true);
        } else {
            setChecked(false);
        }
    };

    const handleResetForm = () => {
        setChecked(false);
        setCountrySelected("");
        $('#checked').prop({checked: false});
        $('#country_form').parsley().destroy();
    };

    const handleShowModal = () => {
        $('#country_form').parsley();

        $("#country_form").on('submit', function(e){
            e.preventDefault();
        });

        $("#modalAddUser").modal("show");
    };

    const reloadTable = () => {
        $('#country').DataTable().ajax.reload();
    };

    let renderButton = () => {
        if(stateReady === true) {
            return(
                <button disabled className="btn btn-brand-02 btn-block">
                    <span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>
                    Please Wait</button>
            )
        } else {
            return(
                <button className="btn btn-brand-02 btn-block">Add to Country Lists</button>
            );
        }
    };

    return (
        <div className="content-body ht-100v pd-b-0">
            <div className="container pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Country</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Country</h4>
                    </div>
                    <div className="d-none d-md-block">
                        <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5 outline-none" onClick={handleShowModal.bind()}><i className="wd-10 mg-r-5 fa fa-plus"></i> Add New</button>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Country List</h6>
                                <div className="d-flex tx-18">
                                    <Link to="#" onClick={reloadTable.bind()} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></Link>
                                    {/*<a href="" className="link-03 lh-0 mg-l-10"><i className="icon ion-md-more"></i></a>*/}
                                </div>
                            </div>
                            <div className="card-body">
                                <table id="country" className="table">
                                    <thead>
                                    <tr>
                                        <th className="wd-20p">Name</th>
                                        <th className="wd-20p">Currency</th>
                                        <th className="wd-20p">Code</th>
                                        <th className="wd-20p">Status</th>
                                        <th className="wd-20p">Actions</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="modalAddUser" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                            <div className="modal-content">
                                <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                    <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </a>
                                    <div className="media-body"><a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" onClick={handleResetForm.bind()} aria-label="Close"><span aria-hidden="true">×</span></a><h4 className="tx-18 tx-sm-20 mg-b-2">Add New</h4><p className="tx-13 tx-color-02 mg-b-0">Add country to the list</p></div>
                                </div>
                                <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                    <form id="country_form" className="parsley-style-1" data-parsley-validate="true" noValidate onSubmit={handleSubmit.bind()}>
                                        <div id="nameWrapper" className="form-group parsley-input">
                                            <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Country</label>
                                            <select className="custom-select"
                                                    id="country_selected"
                                                    value={countrySelected}
                                                    onChange={handleCountrySelected.bind()}
                                                    required>
                                                <option value="" disabled>Select</option>
                                                {countries.map((result) => <option key={result.name} value={result.name}>{result.name}</option>)}
                                            </select>
                                        </div>

                                        <div className="custom-control custom-checkbox mg-b-20">
                                            <input className="custom-control-input" type="checkbox" name="checked[]"
                                                   onChange={handle_check.bind()}
                                                   id="checked"/>
                                            <label className="custom-control-label" htmlFor="checked">Enable</label>
                                        </div>

                                        {renderButton()}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Country;
