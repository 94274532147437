const API_URL = 'https://api.console.adzlok.africa/v5/admin';
// const API_URL = 'https://console.test.adzlok.co.za/v5/admin';
// const API_URL = 'http://127.0.0.1:7001/v5/admin';

exports.SIGNIN_URL = `${API_URL}/signin`;
exports.GET_TOTAL_USER_STATISTICS_URL = `${API_URL}/total-user-statistics`;
exports.GET_USER_STATISTICS_URL = `${API_URL}/user-statistics`;
exports.GET_USER_REGISTRATION_URL = `${API_URL}/user-registration`;
exports.GET_CAMPAIGN_AD_URL = `${API_URL}/campaign-ad`;
exports.GET_CAMPAIGN_AD_DATA_URL = `${API_URL}/campaign-ad-data`;
exports.GET_ADVERTISER_REGISTRAR_URL = `${API_URL}/advertiser`;
exports.GET_INTEREST_URL = `${API_URL}/get-interest`;
exports.GET_TOTAL_CAMPAIGN_STATISTICS_URL = `${API_URL}/total-campaign-statistics`;
exports.GET_CITY_STATISTICS = `${API_URL}/user-region-city`;
exports.GET_SUBURB_STATISTICS = `${API_URL}/user-region-suburb`;
exports.GET_REGIONAL_STATISTICS = `${API_URL}/user-region-state`;
exports.CAMPAIGN_REGISTRAR_URL = `${API_URL}/campaign-registrar`;
exports.CAMPAIGN_ACTION_URL = `${API_URL}/campaign-action`;
exports.DELETE_CAMPAIGN_AD_URL = `${API_URL}/delete-campaign-ad`;
exports.GET_ADVERTISER_PAYMENT_URL = `${API_URL}/advertiser-payment`;
exports.GET_ACCESS_CONTROL_URL = `${API_URL}/access-control`;
exports.GET_COUNTRY_LIST_URL = `${API_URL}/get-country-list`;
exports.DELETE_ADMIN_URL = `${API_URL}/delete-admin`;
exports.ADD_ADMIN_USER_URL = `${API_URL}/add-admin-user`;
exports.GRANT_MASTER_DEVICE_URL = `${API_URL}/grant-master-device`;
exports.ADD_COUNTRY_LIST_URL = `${API_URL}/add-country`;
exports.DELETE_COUNTRY_URL = `${API_URL}/delete-country`;
exports.STATE_COUNTRY_URL = `${API_URL}/state-country`;
exports.ACTION_NOTIFICATION = `${API_URL}/action-notification`;
exports.PUSH_NOTIFICATION = `${API_URL}/push-notification`;
exports.GET_TRANSACTION_HISTORY_URL = `${API_URL}/transaction-history`;
exports.GET_NOTIFICATION_LIST_URL = `${API_URL}/get-notification-list`;
exports.GET_PUBLISHER_AD_URL = `${API_URL}/publisher-ads`;
exports.PUBLISHER_ACTION_URL = `${API_URL}/publisher-action`;
exports.USER_SEARCH_URL = `${API_URL}/user-search`;
exports.UPDATE_USER_ACCOUNT_URL = `${API_URL}/update-user-account`;
exports.DELETE_USER_URL = `${API_URL}/delete-user`;
exports.GET_REGION_DATA = `${API_URL}/get-regions`;
exports.GET_ADVERTISER_DATA = `${API_URL}/get-advertiser`;
exports.UPLOAD_CAMPAIGN_URL = `${API_URL}/create-campaign`;
exports.GET_AD_REPORT_BY_CITY = `${API_URL}/ad-report-city`;
exports.GET_AD_REPORT_BY_SUBURB = `${API_URL}/ad-report-suburb`;
exports.GET_AD_REPORT_URL = `${API_URL}/ad-report`;
