import React from 'react';
import PrivateRoute from '../../components/common/PrivateRoute';
import {Switch, Route} from 'react-router-dom';
import PageError from "../../components/layout/PageError";
import Dashboard from '../../components/authorized/Dashboard';
import CreateAd from '../../components/authorized/advertiser/CreateAd';
import Registration from '../authorized/user/Registration';
import Transaction from "../authorized/user/Transaction";
import Advertiser from '../authorized/advertiser/Advertiser';
import Campaign from '../authorized/advertiser/campaign/Campaign';
import CampaignAd from "../authorized/advertiser/campaign/CampaignAd";
import Payment from "../authorized/advertiser/Payment";
import Invoice from "../authorized/advertiser/Invoice";
import CampaignAdReport from "../authorized/advertiser/campaign/CampaignAdReport";
import AdPublish from "../authorized/apps/AdPublish";
import AdReport from "../authorized/advertiser/campaign/AdReport";
import RegionState from "../authorized/user/RegionState";
import RegionCity from "../authorized/user/RegionCity";
import RegionSuburb from "../authorized/user/RegionSuburb";
import UserModal from "../authorized/shared/UserModal";
import AccessControl from "../authorized/apps/AccessControl";
import Country from "../authorized/apps/Country";
import PushNotification from "../authorized/apps/PushNotification";

const Private = () => {
    return (
        <div>
            <Switch>
                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                <PrivateRoute exact path="/user-registration/:name" component={Registration} />
                <PrivateRoute exact path="/user-region-state/:country" component={RegionState} />
                <PrivateRoute exact path="/user-region-city/:name" component={RegionCity}/>
                <PrivateRoute exact path="/user-region-suburb/:name" component={RegionSuburb}/>
                <PrivateRoute exact path="/transaction" component={Transaction} />
                <PrivateRoute exact path="/access-control" component={AccessControl} />
                <PrivateRoute exact path="/push-notification" component={PushNotification} />
                <PrivateRoute exact path="/country" component={Country} />
                <PrivateRoute exact path="/advertiser" component={Advertiser} />
                <PrivateRoute exact path="/campaign" component={Campaign} />
                <PrivateRoute exact path="/campaign-ad/:id" component={CampaignAd} />
                <PrivateRoute exact path="/campaign-ads-report/:id/:name" component={CampaignAdReport} />
                <PrivateRoute exact path="/ad-report/:ad" component={AdReport}/>
                <PrivateRoute exact path="/payment" component={Payment} />
                <PrivateRoute exact path="/invoice/:encoded" component={Invoice} />
                <PrivateRoute exact path="/create-ads" component={CreateAd}/>
                <PrivateRoute exact path="/ad-publish" component={AdPublish} />
                <PrivateRoute exact path="/error" component={PageError} />
                <Route component={PageError} />
            </Switch>
            <UserModal />
        </div>
    )
};

export default Private;
