import React, { useState, useEffect } from 'react';
import moment from "moment";
import {Link } from 'react-router-dom';
import {getPublisherAd, actionPublisher, getInterest} from '../../../request';
import cogoToast from "cogo-toast";
import $ from "jquery";
import feather from "feather-icons";

const AdPublish = (props) => {

    document.title = "Ad Publish";

    const [search, _search] = useState("");
    const [loading, setLoading] = useState(true);
    const [loadMore, setLoadMore] = useState(false);
    const [secLoading, setSecLoading] = useState(false);
    const [interest, setInterest] = useState("");
    const [pageSize, setPageSize] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [stateSearch, setStateSearch] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState("All Categories");
    const [category, setCategory] = useState(["Automobile", "Technology & Gadgets"]);
    let [publishAd, setPublishAd] = useState([]);

    useEffect(() => {
        getInterest().then((result) => {
            if(result.error !== true) {
                setInterest(result.interest);
                let extract_data = [];
                result.interest.map((data) => {
                    extract_data.push(data.name);
                });

                getPublisherAd(pageSize, extract_data).then((result) => {
                    if(result.error !== true) {
                        setPublishAd(result.data);
                        setTotalPage(result.total);
                        setLoading(false);
                        setCategory(extract_data);
                        if(result.total > 51) {
                            setLoadMore(true);
                        }
                    } else {
                        props.history.push('/error');
                    }
                });
            }
        });
    }, []);

    const handleCatgeories = (name) => {
        // setLoading(true);
        let _category = [];
        _category.push(name);

        if(name === "All Categories") {
            _category = category;
        }

        getPublisherAd(0, _category).then((result) => {
            if(result.error !== true) {
                // setLoading(false);
                setPublishAd(result.data);
                setTotalPage(result.total);
                setSelectedCategory(name);
                result.total === 0 ? setStateSearch(true) : setStateSearch(false);
            }
        });
    };

    const handleSearchFilter = (event) => {
        if(event.target.value.length > 0) {
            setLoadMore(false);
        } else {
            setLoadMore(true);
        }
        _search(event.target.value);
    };

    $(function () {
        feather.replace();
    });

    const requestActionPublish = (id, type, category) => {
        const options = {
            position: "top-right",
            hideAfter: 0
        };

        let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

        actionPublisher(id, type).then((result) => {
            hide();
            const _options = {
                position: "top-right"
            };

            if(result.error === false) {
                cogoToast.success(result.data, _options);
                getPublisherAd(0, category).then((result) => {
                    if(result.error !== true) {
                        setPublishAd(result.data);
                    } else {
                        props.history.push('/error');
                    }
                });
            } else {
                cogoToast.error(result.data, _options);
            }
        });
    };

    const handleLoadMore = () => {
        setSecLoading(true);
        getPublisherAd(publishAd.length, category).then((result) => {
            if(result.error !== true) {
                setSecLoading(false);
                if(result.total > result.data.length + publishAd.length) {
                    setLoadMore(true);
                }

                publishAd = publishAd.concat(result.data);
                setPublishAd(publishAd);
                setTotalPage(result.total);
            } else {
                props.history.push('/error');
            }
        });
    };

    const publishAdContent = () => {
        if(publishAd === null || loading) {
            return(
                <div className="content content-fixed content-auth-alt">
                    <div className="container d-flex justify-content-center ht-100p">
                        <div className="mx-wd-300 wd-sm-450 ht-100p d-flex flex-column align-items-center justify-content-center">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            <p className="mg-t-10">Loading Campaign</p>
                        </div>
                    </div>
                </div>
            )
        } else {
            let renderAd = () => {
                if(publishAd.length <= 0) {
                    return(
                        <div className="container d-flex justify-content-center ht-100p mg-t-50">
                            <div className="ht-100p d-flex flex-column align-items-center justify-content-center">
                                <div className="container ht-100p">
                                    <div className="ht-100p d-flex flex-column align-items-center justify-content-center">
                                        <div className="wd-80p wd-sm-300 mg-b-15 tx-center"><i className="fa-5x fad fa-folder-times"/></div>
                                        <h4 className="tx-20 tx-sm-24 tx-center">Empty</h4>
                                        <p className="tx-color-03 mg-b-40">No Ad is available or has been removed.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }

                const filtered_ad = publishAd.filter((result) => {
                    return result.ad.name.toLowerCase().indexOf(search) !== -1;
                });

                let renderBadge = (type) => {
                    return {
                        premium: <span className="badge badge-warning">Premium</span>,
                        paid: <span className="badge badge-primary">Paid</span>,
                        unpaid: <span className="badge badge-danger">Unpaid</span>
                    }[type]
                };

                let renderBtnOption = (status, id, campaign, category) => {
                    console.log(campaign);
                    return {
                        active: <nav className="nav nav-icon-only mg-t-4">
                            <a href={`#/campaign-ads-report/${campaign._id}/${campaign.name}`} className="nav-link outline-none tx-11"><i className="fa fa-eye"/> View</a>
                            <Link to="#" onClick={requestActionPublish.bind(null, id, "pause", category)} className="nav-link outline-none tx-11"><i className="fa fa-pause"/> Pause</Link>
                        </nav>,
                        achieve: <nav className="nav nav-icon-only mg-t-4">
                            <a href={`#/campaign-ads-report/${campaign._id}/${campaign.name}`} className="nav-link outline-none tx-11"><i className="fa fa-check-circle"/> View</a>
                        </nav>,
                        pause: <nav className="nav nav-icon-only mg-t-4">
                            <a href={`#/campaign-ads-report/${campaign._id}/${campaign.name}`} className="nav-link outline-none tx-11"><i className="fa fa-eye"/> View</a>
                            <Link to="#" onClick={requestActionPublish.bind(null, id, "active", category)} className="nav-link outline-none tx-11"><i className="fa fa-play"/> Resume</Link>
                        </nav>
                    }[status]
                };

                let renderStatus = (status) => {
                    return {
                        active: <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-normal mg-b-8">
                            <span className="tx-semibold">Status: </span>
                            <span className="tx-success tx-semibold">{status}</span></h6>,
                        pause: <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-normal mg-b-8">
                            <span className="tx-semibold">Status: </span>
                            <span className="tx-primary tx-semibold">{status}</span></h6>,
                        achieve: <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-normal mg-b-8">
                            <span className="tx-semibold">Status: </span>
                            <span className="tx-warning tx-semibold">{status}</span></h6>
                    }[status]
                };

                return filtered_ad.map((result) => {
                    return (
                        <div key={result._id} className="col-lg-4 col-md-6">
                            <div className="card card-body pd-t-8 pd-l-8 pd-b-8 mg-b-10 pd-b-4">
                                <div className="media">
                                    <img src={result.ad.img_src} className="ht-120 wd-85 rounded mg-r-10 mg-b-0" alt=""/>
                                    <div className="media-body">
                                        <h6 className="tx-uppercase tx-normal mg-b-8">
                                            {renderBadge(result.type)}</h6>
                                        <h6 className="tx-uppercase tx-12 mg-t-5 tx-spacing-1 tx-color-02 tx-bold mg-b-8">{result.ad.name}</h6>
                                        {renderStatus(result.status)}
                                        <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-normal">
                                            <span className="tx-semibold">Cost Model:</span> {result.ad.cost_model}</h6>
                                        <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 mg-b-0 tx-normal">
                                            <span className="tx-semibold">End Date:</span> {moment(result.ad.end_date).format("Do MMM, YYYY")}</h6>
                                        {renderBtnOption(result.status, result._id, result.ad.campaign, result.ad.category[0])}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                });
            };

            let renderLoadMore = () => {
                if(loadMore && totalPage > publishAd.length) {
                    if(secLoading === true) {
                        return(
                            <div className="text-center mg-t-10">
                                <button className="btn btn-brand-02" disabled>
                                    <span className="spinner-border spinner-border-sm mg-r-10" aria-hidden="true"/>Please Wait</button>
                            </div>
                        )
                    } else {
                        return(
                            <div className="text-center mg-t-10">
                                <button className="btn btn-brand-02" onClick={handleLoadMore.bind()}>Load more</button>
                            </div>
                        );
                    }
                }

                if(loadMore) {
                    return(
                        <div className="text-center mg-t-10">
                            <p className="tx-color-02">--- End of Ads - Total({publishAd.length}) ---</p>
                        </div>
                    );
                }

                return false;
            };

            return(
                <div className="content-body ht-100v pd-b-0">
                    <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
                        <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-20 mg-xl-b-20">
                            <div>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                        <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Ad Publish</li>
                                    </ol>
                                </nav>
                                <h4 className="mg-b-0 tx-spacing--1">Ad Publish</h4>
                            </div>
                            <div className="d-none d-md-block">
                                <button className="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {selectedCategory}
                                </button>
                                <div className="dropdown-menu tx-13">
                                    <Link className="dropdown-item" to="#" onClick={handleCatgeories.bind(null, "All Categories")}>All Categories</Link>
                                    {interest.map((result) => {
                                        return(
                                            <Link key={result.name} className="dropdown-item" to="#" onClick={handleCatgeories.bind(null, result.name)}>{result.name}</Link>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className="mg-b-20">
                            <input type="search" className="form-control" value={search} onChange={handleSearchFilter.bind()} placeholder="Search Ad Name" disabled={stateSearch}/>
                        </div>

                        <div className="row row-xs">
                            {renderAd()}
                        </div>

                        {renderLoadMore()}
                    </div>
                </div>
            );
        }
    };

    return (
        publishAdContent()
    )
};

export default AdPublish;
