// const initialState = {
//     user_data: {
//         total: 0,
//         registered: 0,
//         active: 0,
//         click: 0,
//         current: moment().format("Do MMM, YYYY")
//     },
//     loading: false
// };
//
// export default function(state = initialState, action) {
//     switch(action.type) {
//         case 'USER_STATISTICS':
//             return {
//                 ...state,
//                 user_data: action.payload
//             };
//
//         case 'GET_CAMPAIGN_AD':
//             return {
//                 ...state,
//                 campaign_ad: action.payload,
//                 loading: false
//             };
//
//         case 'CLEAR_CAMPAIGN_AD':
//             return {
//                 ...state,
//                 campaign_ad: null
//             };
//
//         default:
//             return state;
//     }
// }

import {createSlice} from "@reduxjs/toolkit";
import moment from "moment";
import user from "../data/user.json";

const slice = createSlice({
    name: "admin",
    initialState: {
        user_data: {
            total: 0,
            registered: 0,
            active: 0,
            click: 0,
            current: moment().format("Do MMM, YYYY")
        },
        loading: false,
        user_search: user
    },
    reducers: {
        //actions => action handlers
        USER_STATISTICS: (state, action) => {
            return {
                ...state,
                user_data: action.payload.user_data
            };
        },
        USER_SEARCH: (state, action) => {
            return {
                ...state,
                user_search: action.payload.user_search
            };
        }
    }
});

export const {USER_STATISTICS, USER_SEARCH} = slice.actions;
export default slice.reducer;

