import { combineReducers } from 'redux';
import authReducer from './auth';
import userReducer from "./user";
import advertiserReducer from "./advertiser";
import campaginReducer from "./campaign";
import searchReducer from "./search";

export default combineReducers(Object.assign(
    {user: userReducer},
    {auth: authReducer},
    {user: userReducer},
    {advertiser: advertiserReducer},
    {search: searchReducer},
    {campaign: campaginReducer}
));
