import React, {useEffect, useState} from 'react';
import {Link } from 'react-router-dom';
import Search from "../../Search";
import cogoToast from "cogo-toast";
import {campaignAction, getCampaignAdData, deleteCampaignAd} from "../../../../redux/actions";
import $ from "jquery";
import feather from "feather-icons";
import formatNumber from "../../../../utils/formatNumber";
import moment from "moment";

const CampaignAd = (props) => {

    $(function () {
        feather.replace();
    });

    document.title = "Campaign Ads";

    const id = props.match.params.id.replace(/'/g, "");

    const [search, _search] = useState("");
    const [loading, _loading] = useState(true);
    const [campaign, _campaign] = useState(true);
    const [ad_data, _ad_data] = useState([]);

    const handleSearchFilter = (event) => {
        _search(event.target.value);
    };

    useEffect(() => {
        getCampaignAdData(id).then((result) => {
            if(result.error !== true) {
                _campaign(result.data);
                _loading(false);
            } else {
                props.history.push('/error');
            }
        });
    }, []);

    const declineCampaignAd = (ad_id) => {
        const options = {
            position: "top-right",
            hideAfter: 0
        };

        let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

        deleteCampaignAd(id, ad_id).then((result) => {
            hide();
            const _options = {
                position: "top-right"
            };

            if(result.error === false) {
                cogoToast.success(result.data, _options);
                getCampaignAdData(id).then((result) => {
                    if(result.error !== true) {
                        _campaign(result.data);
                    }
                });
            } else {
                cogoToast.error(result.data, _options);
            }
        });
    };

    const showModal = (result) => {

        _ad_data(result);

        $("#myModal").modal("show");
    };

    const requestCampaign = (type) => {
        const options = {
            position: "top-right",
            hideAfter: 0
        };

        let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

        campaignAction(id, type).then((result) => {
            hide();
            const _options = {
                position: "top-right"
            };

            if(result.error === false) {
                cogoToast.success(result.data, _options);
            } else {
                cogoToast.error(result.data, _options);
            }

            // props.history.goBack();
        });
    };

    if(loading) {
        return(
            <div className="content content-fixed content-auth-alt">
                <div className="container d-flex justify-content-center ht-100p">
                    <div className="mx-wd-300 wd-sm-450 ht-100p d-flex flex-column align-items-center justify-content-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        <p className="mg-t-10">Loading Campaign</p>
                    </div>
                </div>
            </div>
        )
    } else {

        let renderAd = () => {
            const filtered_ad = campaign.ads.filter((result) => {
                return result.name.toLowerCase().indexOf(search) !== -1;
            });

            let renderBtnDelete = (id) => {
                if(campaign.ads.length > 1) {
                    return <Link to="#" id="fDelete" className="nav-link outline-none tx-11" onClick={declineCampaignAd.bind(null, id)}><i className="ion-ios-trash tx-15"/> Decline</Link>
                }
                return null;
            };

            return filtered_ad.map((result) => {
                return (
                    <div key={result.name} className="col-lg-4 col-md-6">
                        <div className="card card-body pd-t-8 pd-l-8 pd-b-8 mg-b-10 pd-b-4">
                            <div className="media">
                                <img src={result.img_src} className="ht-120 wd-85 rounded mg-r-10" alt=""/>
                                <div className="media-body">
                                    <h6 className="tx-uppercase tx-12 mg-t-5 tx-spacing-1 tx-color-02 tx-bold mg-b-8">{result.name}</h6>
                                    <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-normal mg-b-8">
                                        <span className="tx-semibold">Cost Model:</span> <span
                                        className="tx-success tx-semibold">{result.cost_model}</span></h6>
                                    <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-normal mg-b-8">
                                        <span className="tx-semibold">Display Time:</span> {result.custom_cost.type}</h6>
                                    <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-normal mg-b-8">
                                        <span className="tx-semibold">Duration:</span> {result.duration} Days</h6>
                                    <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-normal mg-b-0">
                                        <span className="tx-semibold">Budget:</span> R{result.budget}</h6>
                                    <nav className="nav nav-icon-only">
                                        <Link to="#" onClick={showModal.bind(null, result)} className="nav-link outline-none tx-11"><i className="ion-ios-checkmark-circle tx-15"/> View</Link>
                                        {renderBtnDelete(result._id)}
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            });
        };

        return (
            <div>
                <div className="content-body ht-100v pd-b-0">
                    <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
                        <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-20 mg-xl-b-20">
                            <div>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                        <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Campaign</li>
                                    </ol>
                                </nav>
                                <h4 className="mg-b-0 tx-spacing--1">{campaign.name} - R{formatNumber(campaign.budget)}</h4>
                            </div>
                            <div className="d-none d-md-block">
                                <button className="btn btn-sm pd-x-15 btn-white btn-uppercase" onClick={requestCampaign.bind(null, "urgent")}><i data-feather="arrow-up" className="wd-10 mg-r-5"></i> Urgent All</button>
                                <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" onClick={requestCampaign.bind(null, "approve")}><i data-feather="check-circle" className="wd-10 mg-r-5"></i> Approve All</button>
                                <button className="btn btn-sm pd-x-15 btn-danger btn-uppercase mg-l-5" onClick={requestCampaign.bind(null, "decline")}><i data-feather="trash-2" className="wd-10 mg-r-5"></i> Decline All</button>
                            </div>
                        </div>

                        <div className="mg-b-20">
                            <input type="search" className="form-control" value={search} onChange={handleSearchFilter.bind()} placeholder="Search Ad Name"/>
                        </div>

                        <div className="row row-xs">
                            {renderAd()}
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="myModal" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body pd-x-25 pd-sm-x-30 pd-t-40 pd-sm-t-20 pd-b-15 pd-sm-b-20">
                                <a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </a>
                                <div className="nav-wrapper mg-b-20 tx-13">
                                    <div>
                                        <nav className="nav nav-line tx-medium">
                                            <a href="#ad_detail" className="nav-link active" data-toggle="tab">{ad_data.name || "No Data"}</a>
                                            <a href="#advertiser" className="nav-link" data-toggle="tab">Publisher</a>
                                        </nav>
                                    </div>
                                </div>

                                <div className="tab-content">
                                    <div id="ad_detail" className="tab-pane fade active show">
                                        <div className="pd-b-15 d-flex align-items-baseline">
                                            <img src={ad_data.img_src} className="rounded mg-r-10 wd-xl-100p" alt=""/>
                                        </div>

                                        <div className="progress bg-transparent op-7 ht-10 mg-b-20">
                                            <div className="progress-bar bg-primary wd-50p" role="progressbar" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"/>
                                            <div className="progress-bar bg-success wd-25p bd-l bd-white" role="progressbar" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"/>
                                            <div className="progress-bar bg-warning wd-5p bd-l bd-white" aria-valuemax="100"/>
                                            <div className="progress-bar bg-pink wd-5p bd-l bd-white" role="progressbar" aria-valuenow="5" aria-valuemin="0" aria-valuemax="100"/>
                                            <div className="progress-bar bg-teal wd-10p bd-l bd-white" role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"/>
                                            <div className="progress-bar bg-purple wd-5p bd-l bd-white" role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"/>
                                        </div>

                                        <div className="list-group list-group-flush tx-13 mg-b-0">
                                            <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                <strong className="tx-12 tx-rubik">Budget</strong>
                                                <div className="mg-l-auto tx-rubik tx-color-02">{ad_data.budget}</div>
                                            </div>
                                            <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                <strong className="tx-12 tx-rubik">Duration</strong>
                                                <div className="mg-l-auto tx-rubik tx-color-02">{ad_data.duration} Days</div>
                                            </div>
                                            <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                <strong className="tx-12 tx-rubik">Display Time</strong>
                                                <div className="mg-l-auto tx-rubik tx-color-02">{ad_data.duration}</div>
                                            </div>
                                            <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                <strong className="tx-12 tx-rubik">Start Date</strong>
                                                <div className="mg-l-auto tx-rubik tx-color-02">{moment(ad_data.start_date).format("Do MMM, YYYY")}</div>
                                            </div>
                                            <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                <strong className="tx-12 tx-rubik">Cost Model</strong>
                                                <div className="mg-l-auto tx-rubik tx-color-02">{ad_data.cost_model}</div>
                                            </div>
                                            <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                <strong className="tx-12 tx-rubik">Total Clicks</strong>
                                                <div className="mg-l-auto tx-rubik tx-color-02">{ad_data.total_clicks}</div>
                                            </div>
                                            <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                <strong className="tx-12 tx-rubik">Gender</strong>
                                                <div className="mg-l-auto tx-rubik tx-color-02">{ad_data.gender}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="advertiser" className="tab-pane fade">

                                        <h4 className="tx-normal tx-rubik mg-b-10 mg-r-5"> {campaign.advertiser.business_name || "No Data"}</h4>

                                        <div className="progress bg-transparent op-7 ht-10 mg-b-20">
                                            <div className="progress-bar bg-primary wd-50p" role="progressbar" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"/>
                                            <div className="progress-bar bg-success wd-25p bd-l bd-white" role="progressbar" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"/>
                                            <div className="progress-bar bg-warning wd-5p bd-l bd-white" aria-valuemax="100"/>
                                            <div className="progress-bar bg-pink wd-5p bd-l bd-white" role="progressbar" aria-valuenow="5" aria-valuemin="0" aria-valuemax="100"/>
                                            <div className="progress-bar bg-teal wd-10p bd-l bd-white" role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"/>
                                            <div className="progress-bar bg-purple wd-5p bd-l bd-white" role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"/>
                                        </div>

                                        <div className="list-group list-group-flush tx-13 mg-b-0">
                                            <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                <strong className="tx-12 tx-rubik">Email</strong>
                                                <div className="mg-l-auto tx-rubik tx-color-02">{campaign.advertiser.email}</div>
                                            </div>
                                            <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                <strong className="tx-12 tx-rubik">Person Name</strong>
                                                <div className="mg-l-auto tx-rubik tx-color-02">{campaign.advertiser.person_name || "No Data"}</div>
                                            </div>
                                            <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                <strong className="tx-12 tx-rubik">Contact Number</strong>
                                                <div className="mg-l-auto tx-rubik tx-color-02">{campaign.advertiser.contact_number || "No Data"}</div>
                                            </div>
                                            <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                <strong className="tx-12 tx-rubik">Address 1</strong>
                                                <div className="mg-l-auto tx-rubik tx-color-02">{campaign.advertiser.address || "No Data"}</div>
                                            </div>
                                            <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                <strong className="tx-12 tx-rubik">Address 2</strong>
                                                <div className="mg-l-auto tx-rubik tx-color-02">{campaign.advertiser.extra_address || "No Data"}</div>
                                            </div>
                                            <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                <strong className="tx-12 tx-rubik">City</strong>
                                                <div className="mg-l-auto tx-rubik tx-color-02">{campaign.advertiser.city || "No Data"}</div>
                                            </div>
                                            <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                <strong className="tx-12 tx-rubik">State</strong>
                                                <div className="mg-l-auto tx-rubik tx-color-02">{campaign.advertiser.state || "No Data"}</div>
                                            </div>
                                            <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                <strong className="tx-12 tx-rubik">Postal Code</strong>
                                                <div className="mg-l-auto tx-rubik tx-color-02">{campaign.advertiser.postal_code || "No Data"}</div>
                                            </div>
                                            <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                <strong className="tx-12 tx-rubik">Vat Number</strong>
                                                <div className="mg-l-auto tx-rubik tx-color-02">{campaign.advertiser.vat_number || "No Data"}</div>
                                            </div>
                                            <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                                <strong className="tx-12 tx-rubik">Date</strong>
                                                <div className="mg-l-auto tx-rubik tx-color-02">{moment(campaign.advertiser.date_created).format("Do MMM, YYYY") || "No Data"}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

export default CampaignAd;
