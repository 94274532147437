import moment from "moment";

const initialState = {
    campaign_data: {
        total: 0,
        awaiting: 0,
        pending: 0,
        active: 0,
        current: moment().format("Do MMM, YYYY")
    },
    campaign_ad: null,
    loading: false
};

export default function(state = initialState, action) {
    switch(action.type) {
        case 'CAMPAIGN_STATISTICS':
            return {
                ...state,
                campaign_data: action.payload
            };

        case 'CAMPAIGN_STORE':
            return {
                ...state,
                campaign_store: action.payload
            };

        case 'CLEAR_CAMPAIGN_STORE':
            return {
                ...state,
                campaign_store: null
            };

        case 'CAMPAIGN_AD_LOADING':
            return {
                ...state,
                loading: true
            };

        case 'GET_CAMPAIGN_AD':
            return {
                ...state,
                campaign_ad: action.payload,
                loading: false
            };

        case 'CLEAR_CAMPAIGN_AD':
            return {
                ...state,
                campaign_ad: null
            };

        default:
            return state;
    }
}


