import React, { Component } from 'react';
import { HashRouter as Router, Route, Switch} from 'react-router-dom';
import Login from './components/auth/Login';
import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { UNAUTH_USER } from './stores/auth';
import Private from "./components/routes/Private";
import Logout from "./components/auth/Logout";
import {Provider, useDispatch, useSelector} from 'react-redux';
import 'bootstrap';

import {configureStore} from './stores';
import PageError from "./components/layout/PageError";

// //Check for token
if(localStorage.jwtToken) {
    //Set auth token header auth
    setAuthToken(localStorage.jwtToken);

    //Decode token and get user info
    const decoded = jwt_decode(localStorage.jwtToken);

    //Set user and isAuthenticated
    // configureStore().dispatch(setCurrentUser(decoded));

    //Check for expired token
    const currentTime = Date.now() / 1000;
    if(decoded.exp < currentTime) {
        //Logout user
        configureStore().dispatch(UNAUTH_USER());

        //Clear current profile
        window.location.href = "/";
    }
}

class App extends Component {
    render() {
        return (
            <Provider store={ configureStore() }>
                <Router>
                    <div className="App">
                        <Switch>
                            <Route exact path="/" component={Login} />
                            <Route exact path="/logout" component={Logout} />
                            <Route component={Private} />
                        </Switch>
                    </div>
                </Router>
            </Provider>
        );
    }
}

export default App;
