import React from 'react';
import { compose, createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import reducer from '../stores/reducer';
import {autoRehydrate, persistStore} from "redux-persist";
import LocalForage from "localforage";

export const configureStore = (initialState) => {
    const enhancer = createStore(reducer, initialState, compose(
        applyMiddleware(
            thunkMiddleware
        ),
        autoRehydrate()
    ));

    persistStore(enhancer, {storage: LocalForage});
    return enhancer;
};
