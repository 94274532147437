import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {GET_TRANSACTION_HISTORY_URL} from '../../../api';
import 'datatables.net';
import 'datatables.net-responsive';
import moment from 'moment';
import $ from "jquery";
import {Link} from "react-router-dom";

const Transaction = () => {

    document.title = "Transaction History";

    const token = localStorage.getItem('jwtToken');
    const dispatch = useDispatch();

    useEffect(() => {
        $('#transaction').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Transaction Report</p>'
            },
            "searching": true,
            "lengthMenu": [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(GET_TRANSACTION_HISTORY_URL, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        pageSize: data.start,
                        format: 'json',
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success : function(res) {
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: res.data
                        });
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data.name
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return "R"+data.amount
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        if(data.reference === undefined) {
                            return "No record"
                        } else {
                            return data.reference
                        }
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return data.category
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return moment(data.dateCreated).format("Do MMM, YYYY");
                    },
                    "targets": 4
                },
                {
                    "render": function (data) {
                        return '<nav class="nav nav-icon-only"><a href="javascript:void(0)" class="nav-link outline-none"><i class="ion-ios-stopwatch tx-18"></i> No Actions</a></nav>'
                        // return '<nav class="nav nav-icon-only"><a href="#" class="nav-link outline-none"><i class="ion-md-eye tx-18"></i> View</a></nav>'
                    },
                    "targets": 5
                }
            ]
        });
    }, []);

    const reloadTable = () => {
        $('#payment').DataTable().ajax.reload();
    };

    return (
        <div className="content-body ht-100v pd-b-0">
            <div className="container pd-x-0">
                <div
                    className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Transactions</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Transactions</h4>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Transactions List</h6>
                                <div className="d-flex tx-18">
                                    <Link to="#" onClick={reloadTable.bind()} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></Link>
                                    {/*<a href="" className="link-03 lh-0 mg-l-10"><i className="icon ion-md-more"></i></a>*/}
                                </div>
                            </div>
                            <div className="card-body">
                                <table id="transaction" className="table">
                                    <thead>
                                    <tr>
                                        <th className="wd-15p">Name</th>
                                        <th className="wd-10p">Amount</th>
                                        <th className="wd-25p">Reference</th>
                                        <th className="wd-15p">Category</th>
                                        <th className="wd-15p">Date</th>
                                        <th className="wd-10p">Actions</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Transaction;
