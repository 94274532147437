import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import {CAMPAIGN_REGISTRAR_URL} from '../../../../api';
import {
    campaignStatistic,
    campaignAction,
    campaignStore,
    deleteUser,
    getUserStatistic
} from '../../../../redux/actions';
import formatNumber from "../../../../utils/formatNumber";
import cogoToast from "cogo-toast";
import 'datatables.net';
import 'datatables.net-responsive';
import $ from "jquery";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

const Campaign = () => {

    document.title = "Campaigns";
    const token = localStorage.getItem('jwtToken');
    const campaign = useSelector((state) => state.campaign.campaign_data);
    const dispatch = useDispatch();

    const requestCampaign = (id, type) => {
        const options = {
            position: "top-right",
            hideAfter: 0
        };

        let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

        campaignAction(id, type).then((result) => {
            hide();
            const _options = {
                position: "top-right"
            };

            if(result.error === false) {
                dispatch(campaignStatistic());
                $('#campaign').DataTable().ajax.reload();
                cogoToast.success(result.data, _options);
            } else {
                cogoToast.error(result.data, _options);
            }
        });
    };

    const reloadTable = () => {
        dispatch(campaignStatistic());
        $('#campaign').DataTable().ajax.reload();
    };

    useEffect(() => {
        dispatch(campaignStatistic());
        var table = $('#campaign').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Campaign Report</p>'
            },
            "searching": true,
            "lengthMenu": [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(CAMPAIGN_REGISTRAR_URL, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        pageSize: data.start,
                        format: 'json',
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success : function(res) {
                        dispatch(campaignStore(res.data));
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: res.data
                        });
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data.name
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return formatNumber(data.budget)
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.ads.length
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        let renderStatus = (status) => {
                            return {
                                active: '<span class="badge badge-success">Active</span>',
                                complete: '<span class="badge badge-primary">Complete</span>',
                                achieve: '<span class="badge badge-info">Achieved</span>',
                                decline: '<span class="badge badge-danger">Decline</span>',
                                pending: '<span class="badge badge-warning">Pending</span>',
                                awaiting: '<span class="badge badge-info">Awaiting</span>'
                            }[status]
                        };
                        return renderStatus(data.status)
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return data.country
                    },
                    "targets": 4
                },
                {
                    "render": function (data) {
                        let renderStatus = (status) => {
                            return {
                                active: `<nav class="nav nav-icon-only"><a href="#/campaign-ads-report/${data._id}/${data.name}" class="nav-link outline-none"><i class="fa fa-eye"></i> View</a></nav>`,
                                complete: `<nav class="nav nav-icon-only"><a href="#/campaign-ads-report/${data._id}/${data.name}" class="nav-link"><i class="fa fa-eye"></i> View</Link></nav>`,
                                achieve: `<nav class="nav nav-icon-only"><a href="#/campaign-ads-report/${data._id}/${data.name}" class="nav-link"><i class="fa fa-eye"></i> View</Link></nav>`,
                                decline: `<nav class="nav nav-icon-only">
                                                <a href="#/campaign-ad/${data._id}" class="nav-link"><i class="fa fa-eye"></i> View</a>
                                                <a href="#" class="nav-link delete_campaign"><i class="fa fa-trash-alt"></i> Delete</a>
                                            </nav>`,
                                pending: `<nav class="nav nav-icon-only"><a href="#/campaign-ad/${data._id}" class="nav-link"><i class="fa fa-eye"></i> View</a> <a href="#" class="nav-link delete_campaign"><i class="fa fa-trash-alt"></i> Delete</a> </nav>`,
                                awaiting: `<nav class="nav nav-icon-only"><a href="#" onclick="return false" class="nav-link"><i class="fa fa-stop-circle"></i> No Actions</a></nav>`
                            }[status]
                        };

                        return renderStatus(data.status);
                    },
                    "targets": 5
                }
            ]
        });

        $('#campaign').on('click', '.delete_campaign', function() {
            let extract_td = table.row($(this).closest('tr')).data();
            requestCampaign(extract_td.raw._id, "delete");

            return false;
        });
    }, []);

    return (
        <div className="content-body ht-100v pd-b-0">
            <div className="container pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Campaign</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Campaign</h4>
                    </div>
                    <div className="d-none d-md-block">
                        <Link to="/create-ads" className="btn btn-sm pd-x-15 btn-primary btn-uppercase outline-none"><i className="wd-10 mg-r-5 fa fa-user-plus"></i> Add New</Link>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-sm-6 col-lg-3">
                        <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">Total Campaign</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{campaign.total}</h3>
                            </div>
                            <span className="tx-11 tx-color-03 mg-b-0 mg-t-5">Since 12th Dec, 2018</span>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-3 mg-t-10 mg-lg-t-0">
                        <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">Paid Campaign</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{campaign.awaiting}</h3>
                            </div>
                            <span className="tx-11 tx-color-03 mg-b-0 mg-t-5">Since 12th Dec, 2018</span>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-3 mg-t-10 mg-sm-t-0">
                        <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">Pending Campaign</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{campaign.pending}</h3>
                            </div>
                            <span className="tx-11 tx-color-03 mg-b-0 mg-t-5">Date: {campaign.current}</span>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-3 mg-t-10 mg-lg-t-0">
                        <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">Active Campaign</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{campaign.active}</h3>
                            </div>
                            <span className="tx-11 tx-color-03 mg-b-0 mg-t-5">Date: {campaign.current}</span>
                        </div>
                    </div>
                    <div className="col-md-12 mg-t-10">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Campaign List</h6>
                                <div className="d-flex tx-18">
                                    <Link to="#" onClick={reloadTable.bind()} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></Link>
                                    <a href="" className="link-03 lh-0 mg-l-10"><i className="icon ion-md-more"></i></a>
                                </div>
                            </div>
                            <div className="card-body">
                                <table id="campaign" className="table">
                                    <thead>
                                    <tr>
                                        <th className="wd-25p">Name</th>
                                        <th className="wd-15p">Budget</th>
                                        <th className="wd-10p">Ads</th>
                                        <th className="wd-10p">Status</th>
                                        <th className="wd-15p">Country</th>
                                        <th className="wd-15p">Actions</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Campaign;
