import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import cogoToast from "cogo-toast";
import $ from 'jquery';
import {grantMasterDevice, updateUserAccount} from "../../../redux/actions";
import formatNumber from "../../../utils/formatNumber";

const UserModal = () => {

    const dispatch = useDispatch();
    const {user} = useSelector((state) => state.user.user_search);
    const [phone, _phone] = useState("");
    const [wallet, _wallet] = useState("");
    const [redeem, _redeem] = useState("");
    const [_void, __void] = useState(1);
    const [stateReady, _stateReady] = useState(false);
    const [stateMasterReady, _stateMasterReady] = useState(false);

    const handleUserUpdate = () => {
        const option = {
            position: "top-right",
            hideAfter: 3
        };

        if(phone.length === 0 && wallet.length === 0 && redeem.length === 0 && _void === 1) {
            return cogoToast.error("Please, Enter any of the field.", option);
        }

        _stateReady(true);

        let q_phone = phone || user.phone;
        let q_wallet = wallet || user.wallet.balance;
        let q_redeem = redeem || user.redeem.balance;

        dispatch(updateUserAccount(q_phone, user.email, q_redeem, q_wallet, _void, user.country)).then((result) => {
            _stateReady(false);
            if(result.error !== true) {
                _phone("");
                _wallet("");
                _redeem("");
                $('#email_check').prop({checked: false});

                if(document.title === "User Registration") {
                    $('#user').DataTable().ajax.reload(null, false);
                }

                return cogoToast.success(result.data.message, option);
            }
        });
    };

    const handleMasterDevice = () => {
        _stateMasterReady(true);
        grantMasterDevice(user).then((result) => {
            _stateMasterReady(result);
        });
    };

    const handlePhone = (event) => {
        if(event.target.value.length > 0) {
            _phone(event.target.value);
        } else {
            _phone("");
        }
    };

    const handleWalletAmount = (event) => {
        if(event.target.value.length > 0) {
            _wallet(event.target.value);
        } else {
            _wallet("");
        }
    };

    const handleRedeemAmount = (event) => {
        if(event.target.value.length > 0) {
            _redeem(event.target.value);
        } else {
            _redeem("");
        }
    };

    const handle_email_check = () => {
        if($('#email_check').is(':checked')){
            __void(0);
        } else {
            __void(1);
        }
    };

    const handleCloseModal = () => {
        _phone("");
        _wallet("");
        _redeem("");
    };

    let renderSwitchButton = () => {
        if(stateReady === true) {
            return(
                <button className="btn btn-brand-02 float-right" disabled>
                    <span className="spinner-border spinner-border-sm mg-r-10" aria-hidden="true"/>Please Wait</button>
            )
        } else {
            return(
                <button className="btn btn-brand-01 float-right" onClick={handleUserUpdate.bind()}>Update Now <i className="fa fa-marker"/></button>
            );
        }
    };

    let renderMasterButton = () => {
        if(stateMasterReady === true) {
            return(
                <button className="btn btn-brand-02 float-right" disabled>
                    <span className="spinner-border spinner-border-sm mg-r-10" aria-hidden="true"/>Please Wait</button>
            )
        } else {
            return <button className="btn btn-brand-01 float-right" onClick={handleMasterDevice.bind()}><i className="fa fa-mobile"/> Grant Master Device</button>
        }
    };

    let renderEmail = () => {
        if (!('email' in user)) {
            return (
                <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                    <strong className="tx-12 tx-rubik">Email</strong>
                    <div id="brand" className="mg-l-auto tx-rubik tx-color-02">No Records</div>
                </div>
            )
        }

        return(
            <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                <strong className="tx-12 tx-rubik">Email</strong>
                <div className="mg-l-auto tx-rubik tx-color-02">{user.email}</div>
            </div>
        )
    };

    let renderDevice = () => {
        if (!('device' in user)) {
            return (
                <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                    <strong className="tx-12 tx-rubik">Device</strong>
                    <div id="brand" className="mg-l-auto tx-rubik tx-color-02">No Records</div>
                </div>
            )
        }

        return (
            <div>
                <strong className="tx-12 tx-danger">Device Information</strong>
                <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                    <strong className="tx-12 tx-rubik">Brand</strong>
                    <div id="brand" className="mg-l-auto tx-rubik tx-color-02">{user.device.brand}</div>
                </div>
                <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                    <strong className="tx-12 tx-rubik">Model</strong>
                    <div className="mg-l-auto tx-rubik tx-color-02">{user.device.model}</div>
                </div>
                <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                    <strong className="tx-12 tx-rubik">DeviceId</strong>
                    <div className="mg-l-auto tx-rubik tx-color-02">{user.device.uniqueId}</div>
                </div>
                <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                    <strong className="tx-12 tx-rubik">Version</strong>
                    <div className="mg-l-auto tx-rubik tx-color-02">{user.device.version}</div>
                </div>
                <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                    <strong className="tx-12 tx-rubik">Device Update</strong>
                    <div className="mg-l-auto tx-rubik tx-color-02">{moment(user.device.dateCreated).format("Do MMM, YYYY HH:mm")}</div>
                </div>
            </div>
        )
    };

    let renderLocation = () => {
        if (!('location' in user)) {
            return (
                <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                    <strong className="tx-12 tx-rubik">Location</strong>
                    <div id="brand" className="mg-l-auto tx-rubik tx-color-02">No Records</div>
                </div>
            )
        }

        return (
            <div className="mg-t-20 mg-b-20">
                <strong className="tx-12 tx-danger">Location Information</strong>
                <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                    <strong className="tx-12 tx-rubik">State</strong>
                    <div className="mg-l-auto tx-rubik tx-color-02">{user.location.data.slice(-1).pop().region}</div>
                </div>
                <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                    <strong className="tx-12 tx-rubik">City</strong>
                    <div className="mg-l-auto tx-rubik tx-color-02">{user.location.data.slice(-1).pop().city}</div>
                </div>
                <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                    <strong className="tx-12 tx-rubik">Suburb</strong>
                    <div className="mg-l-auto tx-rubik tx-color-02">{user.location.data.slice(-1).pop().suburb}</div>
                </div>
                <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                    <strong className="tx-12 tx-rubik">Latitude</strong>
                    <div className="mg-l-auto tx-rubik tx-color-02">{user.location.data.slice(-1).pop().latitude}</div>
                </div>
                <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                    <strong className="tx-12 tx-rubik">Longitude</strong>
                    <div className="mg-l-auto tx-rubik tx-color-02">{user.location.data.slice(-1).pop().longitude}</div>
                </div>
                <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                    <strong className="tx-12 tx-rubik">Location Update</strong>
                    <div className="mg-l-auto tx-rubik tx-color-02">{moment(user.location.data.slice(-1).pop().lastUpdate).format("Do MMM, YYYY HH:mm")}</div>
                </div>
            </div>
        )
    };

    const renderTransactionList = () => {
        if(user.transaction.length === 0) {
            return(
                <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                    <div id="brand" className="mg-md-auto tx-rubik tx-color-02">No Transaction History</div>
                </div>
            );
        }

        return user.transaction.map((result, index) => {
            return(
                <div className="list-group-item pd-y-5 pd-x-0">
                    <strong className="tx-12 tx-rubik">{index + 1}. {result.name}</strong><br/>
                    <span className="tx-12 tx-rubik">R{formatNumber(result.amount)}</span><br/>
                    <span className="tx-12 tx-rubik">{result.reference}</span><br/>
                    <span className="tx-12 tx-danger">{result.category}</span><br/>
                    <span className="tx-12 tx-rubik">{moment(result.dateCreated).format("Do MMM, YYYY HH:mm")}</span>
                </div>
            );
        })
    };

    return (
        <div className="modal fade" id="user_modal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-body pd-x-25 pd-sm-x-30 pd-t-40 pd-sm-t-20 pd-b-15 pd-sm-b-20">
                        <a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close" onClick={handleCloseModal.bind()}>
                            <span aria-hidden="true">&times;</span>
                        </a>
                        <div className="nav-wrapper mg-b-20 tx-13">
                            <div>
                                <nav className="nav nav-line tx-medium">
                                    <a href="#_user" className="nav-link active" data-toggle="tab">User</a>
                                    <a href="#device" className="nav-link" data-toggle="tab">Device</a>
                                    <a href="#transaction" className="nav-link" data-toggle="tab">Transaction</a>
                                    <a href="#update" className="nav-link" data-toggle="tab">Update</a>
                                </nav>
                            </div>
                        </div>

                        <div className="tab-content">
                            <div id="_user" className="tab-pane fade active show">
                                <div className="list-group list-group-flush tx-13 mg-b-0">
                                    <div className="alert alert-solid alert-dark pd-y-5 pd-x-10 d-flex align-items-center justify-content-between mg-b-8" role="alert">
                                        <span>Wallet Balance</span>
                                        <span>R{formatNumber(user.wallet.balance)}</span>
                                    </div>
                                    <div className="alert alert-solid alert-primary pd-y-5 pd-x-10 d-flex align-items-center justify-content-between mg-b-8" role="alert">
                                        <span>Redeem Balance</span>
                                        <span>R{formatNumber(user.redeem.balance)}</span>
                                    </div>
                                    <div className="alert alert-solid alert-success pd-y-5 pd-x-10 d-flex align-items-center justify-content-between mg-b-20" role="alert">
                                        <span>Transactions</span>
                                        <span>{user.transaction.length}</span>
                                    </div>
                                    <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                        <strong className="tx-12 tx-rubik">Phone</strong>
                                        <div className="mg-l-auto tx-rubik tx-color-02">{user.phone}</div>
                                    </div>
                                    <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                        <strong className="tx-12 tx-rubik">Age</strong>
                                        <div id="age" className="mg-l-auto tx-rubik tx-color-02">{user.age}</div>
                                    </div>
                                    <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                        <strong className="tx-12 tx-rubik">Gender</strong>
                                        <div className="mg-l-auto tx-rubik tx-color-02">{user.gender}</div>
                                    </div>
                                    {renderEmail()}
                                    <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                        <strong className="tx-12 tx-rubik">Country</strong>
                                        <div className="mg-l-auto tx-rubik tx-color-02">{user.country}</div>
                                    </div>
                                    <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                        <strong className="tx-12 tx-rubik">Cycle Count</strong>
                                        <div id="cycle_count" className="mg-l-auto tx-rubik tx-color-02">{user.session.cycle_count}</div>
                                    </div>
                                    <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                        <strong className="tx-12 tx-rubik">Status</strong>
                                        <div className="mg-l-auto tx-rubik tx-color-02">{user.status}</div>
                                    </div>
                                    <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                        <strong className="tx-12 tx-rubik">Viewed Ads</strong>
                                        <div className="mg-l-auto tx-rubik tx-color-02">{user.viewed.length}</div>
                                    </div>
                                    <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                        <strong className="tx-12 tx-rubik">Lockscreen</strong>
                                        <div className="mg-l-auto tx-rubik tx-color-02">{user.lockscreen ? "Enabled": "Disabled"}</div>
                                    </div>
                                    <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                        <strong className="tx-12 tx-rubik">Last Login</strong>
                                        <div className="mg-l-auto tx-rubik tx-color-02">{moment(user.location.lastUpdate).format("Do MMM, YYYY HH:mm")}</div>
                                    </div>
                                    <div className="list-group-item pd-y-5 pd-x-0 d-flex align-items-center">
                                        <strong className="tx-12 tx-rubik">Date Created</strong>
                                        <div className="mg-l-auto tx-rubik tx-color-02">{moment(user.dateCreated).format("Do MMM, YYYY HH:mm")}</div>
                                    </div>
                                </div>
                            </div>

                            <div id="device" className="tab-pane fade">
                                <div className="list-group list-group-flush tx-13 mg-b-0">
                                    {renderDevice()}
                                    {renderLocation()}
                                    {renderMasterButton()}
                                </div>
                            </div>

                            <div id="transaction" className="tab-pane fade">
                                <div className="list-group list-group-flush tx-13 mg-b-0">
                                    {renderTransactionList()}
                                </div>
                            </div>

                            <div id="update" className="tab-pane fade">
                                <div className="list-group list-group-flush tx-13 mg-b-0">
                                    <div className="form-group">
                                        <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Phone Number</label>
                                        <input type="number" className="form-control"
                                               value={phone}
                                               placeholder="Enter phone number"
                                               onChange={handlePhone.bind()}
                                               autoComplete="off"
                                               />
                                    </div>
                                    <div className="form-group">
                                        <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Wallet</label>
                                        <input type="number" className="form-control"
                                               placeholder="Enter amount"
                                               value={wallet}
                                               onChange={handleWalletAmount.bind()}
                                               autoComplete="off"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Redeem</label>
                                        <input type="number" className="form-control"
                                               placeholder="Enter amount"
                                               value={redeem}
                                               onChange={handleRedeemAmount.bind()}
                                               autoComplete="off"
                                        />
                                    </div>
                                    <div className="custom-control custom-checkbox mg-b-20">
                                        <input className="custom-control-input" type="checkbox" name="age[]"
                                               onChange={handle_email_check.bind()}
                                               id="email_check"/>
                                        <label className="custom-control-label" htmlFor="email_check">Enable email address</label>
                                    </div>
                                    {renderSwitchButton()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserModal;
